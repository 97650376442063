import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'

import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import axios from 'axios'
  Vue.prototype.$axios = axios;
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'
import VueToasted from 'vue-toasted'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate"
import uk from "vee-validate/dist/locale/uk.json"
import * as rules from "vee-validate/dist/rules"

import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  //componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
  //...,                // ...other defaults
});


import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';


import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './css/main.css'
import './css/media_sm.css'
import './css/media_xs.css'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});


// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);


const EmailIsFree = (value) => {
  return axios.post('/', { "action":"checkemail", "email": value })
    .then((response) => {
      console.log(response)
      return {
        valid: response.data.free2use,
        data: {
          message: response.data.message
        }
      };
    });
};

/*
extend('EmailIsFree', {
  validate: EmailIsFree,
  getMessage: field => field + ' вже використовується у системі'
});
*/

extend('EmailIsFree', {
  validate: EmailIsFree
});


const dictionary = {
  uk: {
    messages: {
      email: 'Невірний формат електронної адреси',
      EmailIsFree: 'Такий е-мейл вже використовується в системі'
    }
  }
};

localize(dictionary);

localize("uk", uk);

/*
const myRule = {
  getMessage(field, params, data) {
      return (data && data.message) || 'Something went wrong';
  },
  validate(value) {
    return new Promise(resolve => {
      resolve({
        valid: value === 'trigger' ? false : !! value,
        data: value !== 'trigger' ? undefined : { message: 'Not this value' }
      });
    });
  }
};
*/


/*
Validator.extend('verify_password', {
  getMessage: field => `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
  validate: value => {
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      return strongRegex.test(value);
  }
});
*/

extend('phone', {
  getMessage: field => 'Номер телефона має бути в міжнарондому форматі'+field,
  validate: value => {
      var phoneRegex = new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/);
      return phoneRegex.test(value);
  }
});

/*
const valid_phone = {
  getMessage(field, args) {
    return 'Номер фону бла-бла-бла'+field+'='+args
  },
  validate(value, args) {
    var phoneRegex = new RegExp("/^[2-9]\d{2}[2-9]\d{2}\d{4}$/");
    return phoneRegex.test(value);
  }
};
*/


Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueToasted)
Vue.use(VueTelInput);
Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    'Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер',
    "П'ятниця", 'Субота'
  ],
  dayOfWeekNamesShort: [
    'Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'
  ],
  monthNames: [
    'січня', 'лютого', 'березня', 'квітня', 'травня', 'червня',
    'липня', 'серпня', 'вересня', 'жовтня', 'листопада', 'грудня'
  ],
  monthNamesShort: [
    'Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер',
    'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру'
  ],
  // Timezone offset, in minutes (0 - UTC, 180 - Russia, undefined - current)
  //timezone: 120
})

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('uk-UA', {
      style: 'currency',
      currency: 'UAH',
      currencyDisplay: 'narrowSymbol'
  });
  return formatter.format(value);
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  var upper = []
  value.split(" ").forEach((word) => {
    upper.push(word.toUpperCase())
  })
  return upper.join(" ")
})


new Vue({
  render: h => h(App),
}).$mount('#app')
