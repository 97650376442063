<template>
    <div class="abonements">
        <b-row>
            <b-col md="12">
                <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner
                    spinner-variant="bruvis"
                >
                    

                  <!-- 1st page -->

                  <template v-if="!widget_is_visible">
                    <!--b-tabs card>
                      <b-tab title="Абонементи" active-->

                        <template  v-for="a in abonements">
                          <b-card 
                            :key="a.rn" 
                            :title="a.fullname" 
                            :sub-title="a.comments" 
                            footer-tag="footer" 
                            class="mb-4 abons_card"
                            v-if="!targetAbon || targetAbon == a.rn"
                            @click = "requestAbon(a)"
                          >
                            <b-card-text class="font-weight-bold">
                              <div style="display:flex;justify-content: space-between;align-items: flex-end;">

                                <div style="display:flex;flex-direction: column;">  
                                  <template v-if="a.permax!=0">
                                    <template v-if="a.permaxtype===1"><!-- Тип для обмеження кількості: 0 - відвідувань, 1 - днів -->
                                      <div>{{a.permax}} {{wordDays(a.permax)}}</div>
                                    </template>
                                    <template v-else>
                                      <div>{{a.permax}} {{wordVisits(a.permax)}}</div>
                                    </template> 
                                  </template>    

                                  <template v-if="a.perlen!=0 || a.pertype===3">
                                    <!-- Тип періоду: 0 - Місяць, 1 - День, 2 - Безстроковий, 3 - Календарний місяць -->
                                    <div v-if="a.pertype===0">Термін дії: {{a.perlen}} {{wordMonths(a.perlen)}}</div>
                                    <div v-else-if="a.pertype===1">Термін дії: {{a.perlen}} {{wordDays(a.perlen)}}</div>
                                    <div v-else-if="a.pertype===3">Термін дії: календарний місяць</div>
                                  </template>
                                </div> 


                                <!--
                                <template v-if="a.permaxtype===1">
                                  <div v-if="a.pertype===0">{{a.perlen}} {{wordMonths(a.perlen)}}</div>
                                  <div v-else-if="a.pertype===1">{{a.perlen}} {{wordDays(a.perlen)}}</div>
                                  <div v-else-if="a.pertype===3">Календарний місяць</div>
                                  <div v-else>Безстроковий</div>
                                </template>
                                <template v-else-if="a.permaxtype===0">
                                  <div>{{a.permax}} {{wordVisits(a.permax)}}</div>
                                </template>  
                                -->


                                <div>
                                  {{a.price | toCurrency }}
                                </div>
                              </div>      


                            </b-card-text>
                            <!--template #footer  v-if = "((selectedAbon && selectedAbon.rn==a.rn) || targetAbon) && !widget_is_visible"-->
                            <template #footer  v-if = "selectedAbon && selectedAbon.rn==a.rn && !widget_is_visible">  
                              <div id="abons_footer">
                                <template v-if="!a.post_act">
                                  <!--
                                  <b-button 
                                    v-if="!selectedAbon || selectedAbon.rn != a.rn"
                                    @click="requestAbon(a)" 
                                    variant="victor" 
                                    class=""
                                    block
                                    >Замовити
                                  </b-button>
                                  -->
                                  <!--------v-else-if="selectedAbon && selectedAbon.rn==a.rn && !widget_is_visible"-------------------------------->

                                  <template >
                                    <div class="footer-abon-2">
                                      <div class="pr-2">Дата активації : </div> 
                                      <div>
                                        <b-form-datepicker 
                                          class="shadow-none"
                                          placeholder="Дата активації" 
                                          locale="uk"
                                          :date-format-options="{ year: 'numeric', month: 'long', day: '2-digit', weekday: 'short' }"
                                          v-model="dateFr"
                                          block
                                          dropup
                                          :min= "minOndate(selectedAbon.permaxtype)"
                                          :max= "maxOndate(selectedAbon.permaxtype,selectedAbon.pertype,selectedAbon.perlen)"
                                          v-b-popover.hover.bottomleft.v-victor="{ 
                                            customClass: 'bg-victor text-black font-weight-bold popover', 
                                            content :'Оберіть дату, з якої ви бажаєте активувати цей абонемент'
                                          }"
                                        >  
                                        </b-form-datepicker>
                                      </div>  
                                    </div>
                                    <div v-if="dateTo"  class="footer-abon-2 py-2">
                                      <div class="pr-2">Дійсний до :</div>
                                      <div><strong>{{new Date(dateTo) | dateFormat('dddd D MMMM YYYY')}}</strong></div> 
                                    </div>
                                    <div id="footer-abon-3" class="text-center pt-2">
                                      <b-button variant="victor" block :disabled="!dateFr" class=""  @click="goBuy(selectedAbon.rn)">
                                        <!--b-icon icon="credit-card2-back" class="mr-2"></b-icon>Cплатити <b>{{selectedAbon.price | toCurrency }}</b-->
                                        Замовити
                                      </b-button>
                                    </div>  
                                  </template>

                                  <!--------------------------------------->
                                </template>
                                <template v-else>
                                  <div class="text-center pb-2">Активується при першому відвідуванні</div>
                                  <b-button variant="victor" :disabled="false" class="pt-2" block @click="requestAndPayAbon(a.rn)">
                                    <b-icon icon="credit-card2-back" class="mr-2"></b-icon>Cплатити
                                  </b-button>
                                </template>  
                              </div>  
                            </template>
                          </b-card>

                        </template>
                      <!--/b-tab>
                      <b-tab title="Абонементи таблиця">
                        <b-table hover sticky-header
                          v-if="abonements"
                          :items="abonements" 
                          :fields="fields"
                        >
                         
                        </b-table>
                      </b-tab>
                      
                    </b-tabs-->
                  </template>

                  <!-- 2nd -->

                  <template v-if="selectedAbon && !widget_is_visible && '1'=='2'">
                    <b-card :title="selectedAbon.fullname" :sub-title="selectedAbon.comments" footer-tag="footer" class="mb-4 abons_card">
                            <b-card-text class="font-weight-bold">
                              <hr>
                              <b-row>
                                <b-col class="text-right">Дата активації : </b-col> 

                                <b-col>
                                  <b-form-datepicker 
                                    placeholder="Дата активації" 
                                    locale="uk"
                                    :date-format-options="{ year: 'numeric', month: 'long', day: '2-digit', weekday: 'short' }"
                                    v-model="dateFr"
                                    :min= "minOndate(selectedAbon.permaxtype)"
                                    :max= "maxOndate(selectedAbon.permaxtype,selectedAbon.pertype,selectedAbon.perlen)"
                                    v-b-popover.hover.bottomleft.v-victor="{ 
                                      customClass: 'bg-victor text-black font-weight-bold popover', 
                                      content :'Оберіть дату, з якої ви бажаєте активувати цей абонемент'
                                    }"
                                  >  
                                  </b-form-datepicker>
                                </b-col>  

                                <!--b-col>{{new Date(dateFr) | dateFormat('dddd D MMMM YYYY')}}</b-col--> 
                              </b-row>
                              <b-row v-if="dateTo">
                                <b-col class="text-right">Дійсний до :</b-col> 
                                <b-col>{{new Date(dateTo) | dateFormat('dddd D MMMM YYYY')}}</b-col> 
                              </b-row>
                            </b-card-text>
                            <template #footer >
                              <div id="abons_footer2" class="text-center">
                                <b-button variant="victor" :disabled="false" class="ml-2" @click="resetSelectedAbon()">
                                  <b-icon icon="arrow-left" class="mr-2"></b-icon>Повернутись
                                </b-button>
                                <b-button variant="victor" :disabled="!dateFr" class="ml-2"  @click="goBuy(selectedAbon.rn)">
                                  <b-icon icon="credit-card2-back" class="mr-2"></b-icon>Cплатити <b>{{selectedAbon.price | toCurrency }}</b>
                                </b-button>
                              </div>  
                            </template>
                          </b-card>
                  </template>  

                </b-overlay> 

                <div id="liqpay_checkout"></div>

            </b-col>
        </b-row>    


    </div>
</template>

<script>

export default {
  name: 'AboneMents',
  props: {
    targetAbon:{
        type: Object,
        required: false
    },
    dateFRtargetAbon:{
        type: Object,
        required: false      
    }
    
  },
  data: () => ({
    abonements : null, 
    busy: true,
    dateActivation: {},
    selectedAbon: null,
    dateFr: null,
    //dateTo: null,
    lpay: null,
    widget_is_visible: false,
    globalrns: null,
    //targetAbon: null,
    
    fields: [
            

    ],

  }),  
  methods: {
        getAbonements(){
            this.busy = true;
            this.$axios.post('/', {"action":"getabonements"} )
                .then((response) => {
                if(response.data.success != false && response.data.success != 'false'){
                    this.abonements = response.data.abonements
                    if (this.abonements && this.targetAbon){
                      this.requestAbonByRn(this.targetAbon)
                      let dt

                      if(this.dateFRtargetAbon){
                        dt = new Date(this.dateFRtargetAbon)
                        dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()+1)
                      }else{
                        dt = new Date() //now
                      } 
                      this.dateFr = dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate() 
                    }  
                    this.busy = false;

                }else{

                    this.busy = false;
                    if(response.data.error=="auth" || response.data.error=="init"){
                        this.$emit('needlogin')
                    }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                    }
                }  
                })
                .catch(error => {
                    this.busy = false
                    console.error('There was an error!', error)
                });
        },
        onRowClicked(item) {
          console.log(item)
        },
        wordMonths(m){
          if(m===null) return ''
          m= parseInt(m)
          if(m===null) return ''
          var mm = m % 10
          if(mm==1 && m!=11) return 'місяць'
          else if(mm >0 && mm<5 && (m<11 || m>19)) return 'місяця'
          else return 'місяців'
        },
        wordMonthsCal(m){
          if(m===null) return ''
          m= parseInt(m)
          if(m===null) return ''
          var mm = m % 10
          if(mm==1 && m!=11) return 'календарний місяць'
          else if(mm >0 && mm<5  && (m<11 || m>19) ) return 'календарних місяця'
          else return 'календарних місяців'
        },
        wordDays(d){
          if(d===null) return ''
          d= parseInt(d)
          if(d===null) return ''
          var dd = d % 10
          if(dd==1 && d!=11) return 'день'
          else if(dd >0 && dd<5 && (d<11 || d>19)) return 'дні'
          else return 'днів'
        },
        wordVisits(d){
          if(d===null) return ''
          d= parseInt(d)
          if(d===null) return ''
          var dd = d % 10
          if(dd >0 && dd<5 && (d<11 || d>19)) return 'відвідування'
          else return 'відвідувань'
        },
         minOndate: function(){
          //const now = new Date()
          //const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
          //const zavtra = new Date(now.getFullYear(), now.getMonth(), now.getDate()+1)
          //const minDate = new Date(today)
          const minDate = new Date()
          //return minDate
          return minDate
        },
        maxOndate: function(){
          const now = new Date()
          const todayNextMonth = new Date(now.getFullYear(), now.getMonth()+1, now.getDate())
          const maxDate = new Date(todayNextMonth)
          return maxDate
        },
        requestAbon(a){
          //if(!this.dateActivation[rn]) return
          if(this.selectedAbon != a){
            this.dateFr = null
            this.selectedAbon = a
          }  
          /*
          this.abonements.forEach(a => {
            console.log('Abons',a.rn,a)                          
            if(a.rn===rn){
              this.dateFr = null //this.dateActivation[rn]
              //this.dateTo = this.getDateTo(a.pertype,a.perlen)
              this.selectedAbon = a
            }  
          });
          */
        },
        requestAbonByRn(rn){

          console.log('Rn',rn)
          console.log('Abonements',this.abonements)

          this.abonements.forEach(a => {
            if(a.rn===rn){
              this.dateFr = null
              this.selectedAbon = a
            }  
          });
        },
        requestAndPayAbon(rn){
          this.selectedAbon = null
          this.dateFr = null
          //this.dateTo = null
          this.abonements.forEach(a => {
            if(a.rn===rn){
              this.selectedAbon = a
            }  
          });
          this.goBuy(rn)
        },
        /*
        getDateTo(pertype,perlen){
          const startDate = new Date(this.dateFr)
          var endDate
          if((perlen==0 && pertype!=3) || pertype==2) return // 2 - безстроковий
          if(pertype===0){ // місяць
            endDate = new Date(startDate.setMonth(startDate.getMonth()+perlen))
          }else if(pertype===1){ // день
            endDate = new Date(startDate.setDate(startDate.getDate()+perlen))
          }else if(pertype===3){ // календарний місяць
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
          }
          return endDate
        },
        */
        resetSelectedAbon(){
          this.selectedAbon=null
          /*
          Object.keys(this.dateActivation).forEach(a => {
            this.dateActivation[a]=null
          });
          */
          this.dateFr = null
          //this.dateTo = null
        },

        goBuy(rn){
          this.busy = true
          const date = this.dateTo
          var dt =''

          if(date){
            dt += date.getFullYear()
            dt += (date.getMonth()<9)?'-0':'-'
            dt += date.getMonth()+1
            dt += (date.getDate()<10)?'-0':'-' 
            dt += date.getDate()
          }  

          var postData = {
            "action"  :"buyabonement",
            "date"    : this.dateFr,
            "dateto"  : dt,
            "rn"      : rn
          }

          console.log('PostData',postData)

          var axiosConfig = {
            headers: {
              'Content-Type': 'application/json',
            },
            'withCredentials': true
          }

          this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            console.log(response.data)
            if(response.data.success != false && response.data.success != 'false'){

              this.$emit('updateabons')
              
              if(response.data.liqpay.data && response.data.liqpay.signature){
                
                this.globalrns = response.data.checkRN
                this.goLiqPay(response.data.liqpay.data, response.data.liqpay.signature,"#liqpay_checkout")
              }
              
            }else{
              this.errorMaster(response.data.error)
            }  
          })
          .catch(error => {
              console.error('There was an error!', error)
              this.book_busy = false
              this.$toasted.error('Error').goAway(2000)
          });  

    },

    goLiqPay(dt, sign, el){
                
                if(this.lpay){
                  //eslint-disable-next-line
                  this.lpay = LiqPayCheckout.init({
                    "data": dt,
                    "signature": sign,
                    "embedTo": el,
                    "language": "uk",
                    "mode": "embed" // embed || popup
                  })
                }else{
                  //eslint-disable-next-line
                  this.lpay = LiqPayCheckout.init({
                    "data": dt,
                    "signature": sign,
                    "embedTo": el,
                    "language": "uk",
                    "mode": "embed" // embed || popup
                  }).on("liqpay.callback", function(data){  
                      console.log('Callback '+this.globalrns)
                      console.log(data.status)

                      if(data.status=='failure' && data.code=='expired'){
                        this.$toasted.error('Час на оплату скінчився! Бронювання буде скасовано.').goAway(4000)
                        this.busy = true
                        //this.checkedSeats = []
                        //this.currentDateTabMark = null
                        //this.updateStates()
                        this.busy = false
                      }else{
                        setTimeout(() => this.requestToPayStatus(this.globalrns), 2000)
                      }
                    }.bind(this)
                  ).on("liqpay.ready", function(data){
                    console.log(data.status)
                    this.busy = false
                    this.widget_is_visible = true
                    }.bind(this)
                  ).on("liqpay.close", function(data){
                    // close
                    console.log('Close')
                    console.log(data)
                  });
                }
    },

    requestToPayStatus(rns){

      this.$axios.post('/', {"action":"getpaystatusabon","rn":rns} )
        .then((response) => {
          if(response.data.success != false && response.data.success != 'false'){
            let result = response.data
            switch(result.status){
              case 'wait':
                console.log('wait')
                this.$toasted.info('Очікуємо на підтвердження платежу').goAway(2000)
                setTimeout(() => this.requestToPayStatus(rns), 2000)
                break
              case 'success':
                console.log('success')
                this.$toasted.success(result.message).goAway(3000)
                //---оплата прошла успешно
                this.book_busy = false
                 this.$emit('onupdate')
                 this.resetSelectedAbon()
                //if(this.auth && this.auth.visitor) this.getTickets()
                break
              default:
                console.log(result)  
            } 
          }else{
            this.errorMaster(response.data.error)
          }   
        })
        .catch(error => {
          this.globalbusy = false
          console.error('There was an error!', error)
        });
    },

    //************************************************

    errorMaster(er){
      if(er=="init"){
        if(this.auth){
          this.$emit('needlogin')
          console.log('Re LOGIN')
        }else{
          this.$emit('needlogin')
          //this.init()
          console.log('Re INIT')
        }
      }if(er=="auth"){
        this.$emit('needlogin')
      }else{
        console.log('Re INKNOWN',er)
      }
    },


    //--------------------------------------------



        /*
        handleHover(hovered,e) {
          console.log('trgd',e.srcElement.id.substr(-5))
          this.isHovered[e.srcElement.id.substr(-5)] = hovered
          console.log(this.isHovered[e.srcElement.id.substr(-5)])
        }
        */
    
  },
  computed:{
    dateTo: function(){
      if(!this.selectedAbon || !this.dateFr) return
      const startDate = new Date(this.dateFr)
      const pertype = this.selectedAbon.pertype
      const perlen = this.selectedAbon.perlen
      var endDate
      if((perlen==0 && pertype!=3) || pertype==2) return // 2 - безстроковий
      if(pertype===0){ // місяць
        endDate = new Date(startDate.setMonth(startDate.getMonth()+perlen))
      }else if(pertype===1){ // день
        endDate = new Date(startDate.setDate(startDate.getDate()+perlen))
      }else if(pertype===3){ // календарний місяць
        endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
      }
      return endDate
    } 
  },
  
  mounted() {
    this.getAbonements()

    console.log('TargetAbon',this.targetAbon)
/*
    if(this.targetAbon) {
      
      this.requestAbonByRn(this.targetAbon)
      const now = new Date()
      this.dateFr = now.getFullYear()+'-'+now.getMonth()+'-'+now.getDate()
    }
*/    
  },

  created() {
    let liqpay = document.createElement('script');    
    liqpay.setAttribute('src',"https://static.liqpay.ua/libjs/checkout.js");
    document.head.appendChild(liqpay);
  },

}
</script>

<style scoped>

/*
#abons_footer{
  display: flex;
}
*/

.footer-abon-2{
  display: flex;
  align-items: center;
  justify-content: space-between;
}  

#abons_footer2{
  display: flex;
  justify-content: center;
}

.abons_card{
  background: #f0f0f0;
}

/*
@media (max-width: 575px) {

}


@media (hover: none) {

}    

*/

</style>
