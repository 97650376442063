<template>
    <div class="archive">
        <b-row>
            <b-col xl="4" lg="4" md="5">
                <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner
                    spinner-variant="bruvis"
                >
                    <v-calendar
                        is-expanded
                        :first-day-of-week="2"
                        ref="calendar" 
                        masks="YYYY-MM-DD" 
                        :locale="{ id: 'uk', firstDayOfWeek: 2, masks: { weekdays: 'WW' } }"
                        :attributes.sync = "attrs"
                        
                        :available-dates.sync="availDates"
                        :highlight ="true"
                        :rows="$screens({ default: 1, xl: 2, lg: 2})"
                        @dayclick="dayClicked"
                        style="margin:0 auto"
                    /><!--:max-page="{year: (new Date()).getFullYear(), month: (new Date()).getMonth()+1}"-->
                    <!-- надо будет вычислять и ставить сюда максимальную дату визита-->
                </b-overlay>    
            </b-col>
            <b-col xl="8" lg="8" md="7">
                    <b-alert show variant="light" class="text-center" v-if="dt_id">
                        <b-icon v-if="dt_index > 0" @click="gotoDateIndex(dt_index-1)" icon="chevron-left" class="mr-2" role="button"/>
                        {{new Date(dt_id) | dateFormat('dddd D MMMM YYYY')}}
                        <b-icon v-if="dt_index < days_payed.length-1" @click="gotoDateIndex(dt_index+1)" icon="chevron-right" class="ml-2" role="button"/>

                    </b-alert>
                    <template v-if="zakazy_day != null">
                      <template  v-for="(ch,index2) of zakazy_day">  

                        <div :class="(ch.rt===1)?'perforated_check mx-auto':'perforated_check perforated_check_abon mx-auto'" :key="index2">
                          <svg width="200px" viewBox="0 0 280 5" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0,0 280,0" fill="none" stroke="#e2e3de" stroke-dasharray="3 3" stroke-width="20"/>
                          </svg>
                          <template v-if="ch.penalty==0 && (ch.ftimefr || ch.ftimeto)"><div class="shtamp-success">Використано!</div></template>
                          <template v-if="ch.penalty!=0"><div class="shtamp-penalty">Використано!</div></template>
                          <template v-if="ch.rt===1">
                          <!---------ticket begin-------------------------------------------------->  
                            <b-icon
                              v-if="ch.ticketRN && ch.paytime"
                              icon="download" 
                              class="ico-download"
                              variant="dark"
                              font-scale="1.3"
                              v-b-popover.hover.topright="'Загрузити квиток'"
                              role="button"
                              @click="ticketDownload(ch.ticketRN,ch.code)"
                            ></b-icon>
                            <b-icon
                              v-else-if="!ch.paytime &&  ch.payUpTo && new Date() < new Date(ch.payUpTo)"
                              icon="credit-card" 
                              class="ico-download"
                              variant="dark"
                              font-scale="1.3"
                              v-b-popover.hover.topright="'Оплатити'"
                              role="button"
                              @click="$emit('onPay')"
                              
                            ></b-icon><!--@click="ticketDownload(ch.ticketRN,ch.code)"-->
                            <table class="teble mx-auto w-100">
                              <tr :key="index2">
                                <th colspan=2 class="text-center pb-3 pt-3"
                                  :style="(!ch.paytime && (!ch.payUpTo || new Date() > new Date(ch.payUpTo)))?'text-decoration: line-through;':''"
                                >
                                  Квиток № {{ch.ticketRN}}<br>
                                  {{new Date(dt_id) | dateFormat('DD.MM.YYYY')}}<br>
                                  {{new Date(ch.ptimefr) | dateFormat('HH:mm')}}-{{new Date(ch.ptimeto) | dateFormat('HH:mm')}} 
                                  <!--template v-if="ch.canceled"><div class="shtamp-canceled">Анульовано!</div></template-->
                                </th>
                              </tr>
                              <tr :key="index2">
                                <td class="text-left pr-3 pl-1">{{ch.xnomenu}}</td>
                                <td class="text-right text-nowrap pl-3 pr-1" v-if="seatinfo && ch.seatRN && seatinfo[ch.seatRN]">{{(seatinfo[ch.seatRN]).objname}}</td>
                              </tr>
                              <tempalte v-if="ch.xspecialistMnemos">
                                <tr :key="index2">
                                  <td colspan="2" class="text-left pr-3 pl-1">{{ch.xspecialistMnemos}}</td>
                                </tr>
                              </tempalte>
                              <template v-else-if="ch.grpname">
                                <tr :key="index2">
                                  <td class="text-left pr-3 pl-1">Група</td>
                                  <td class="text-right pl-3 pr-1">{{ch.grpname}}</td>
                                </tr>
                              </template>
                              <template v-else>
                                <tr :key="index2">
                                  <td colspan="2" class="text-left pr-3 pl-1">{{getSeatLabel(ch.namerow,ch.rownum)}} {{getSeatLabel(ch.namecol,ch.seat)}}</td>
                                </tr>
                              </template>  
                              <tr :key="index2" v-if="ch.paytime">
                                <th class="text-left pr-3 pl-1">Разом сплачено</th>
                                <th class="text-right pl-3 pr-1">{{ch.price | toCurrency}}</th>
                              </tr>
                              <tr :key="index2" v-else>
                                <th class="text-left pr-3 pl-1">До сплати</th>
                                <th class="text-right pl-3 pr-1">{{ch.price | toCurrency}}</th>
                              </tr>
                              <!--tr :key="index2" v-if="ch.canceled">
                                <th class="text-left pr-3 pl-1 comment-canceled">Повернено на баланс клубної карти</th>
                                <th class="text-right pl-3 pr-1 comment-canceled">{{ch.sumToPay | toCurrency}}</th>
                              </tr-->
                            </table>  
                          <!---------ticket end-------------------------------------------------->  
                          </template>
                          <template v-else-if="ch.rt===0">
                          <!--------abon visite begin-------------------->
                            <b-icon
                              icon="x-circle-fill" 
                              class="ico-del-zakaz "
                              variant="danger"
                              font-scale="1.3"
                              v-b-popover.hover.topright="'Анулювати візит'"
                              role="button"
                              @click="annulVisit(ch.visitRN)"
                              v-if="new Date() < new Date(ch.ptimefr) && !ch.ftimefr && !ch.ftimeto"
                            ></b-icon>
                            <table class="teble mx-auto w-100">
                              <tr :key="index2">
                                <th colspan=2 class="text-center pb-3 pt-3">
                                  Абонемент "{{ch.xnomenu}}"<br>
                                  <!--Квіток № {{v.rn}}<br-->
                                  {{new Date(dt_id) | dateFormat('DD.MM.YYYY')}}<br>
                                  {{new Date(ch.ptimefr) | dateFormat('HH:mm')}}-{{new Date(ch.ptimeto) | dateFormat('HH:mm')}} 
                                  <!--template v-if="ch.canceled"><div class="shtamp-canceled">Анульовано!</div></template-->
                                </th>
                              </tr>
                              <tr :key="index2">
                                <td class="text-left pr-3 pl-1">{{ch.xnomenu}}</td>
                                <td class="text-right text-nowrap pl-3 pr-1" v-if="seatinfo && ch.seatRN && seatinfo[ch.seatRN]">{{(seatinfo[ch.seatRN]).objname}}</td>
                              </tr>
                              <tr :key="index2" v-if="ch.xspecialistMnemos">
                                <td colspan="2" class="text-left pr-3 pl-1">{{ch.xspecialistMnemos}}</td>
                              </tr>
                              <tr :key="index2"  v-else-if="ch.grpname">
                                <td class="text-left pr-3 pl-1">Група</td>
                                <td class="text-right pl-3 pr-1">{{ch.grpname}}</td>
                              </tr>
                              <tr :key="index2" v-else>
                                <td colspan="2" class="text-left pr-3 pl-1">{{getSeatLabel(ch.namerow,ch.rownum)}} {{getSeatLabel(ch.namecol,ch.seat)}}</td>
                              </tr>
                              <!--tr :key="index2" v-if="ch.canceled">
                                <th class="text-left pr-3 pl-1 comment-canceled">Повернено на баланс клубної карти</th>
                                <th class="text-right pl-3 pr-1 comment-canceled">{{ch.sumToPay | toCurrency}}</th>
                              </tr-->
                            </table>    
                          <!--------abon visite end-------------------->
                          </template>  



                          
                        </div>

                      </template>
                    </template>
            </b-col>
        </b-row>    

            <!--div v-if="dt && dt.id && zakazy_payed[dt.id]">
                <pre>{{zakazy_payed[dt.id]}}</pre>
            </div-->    

<!--  date-picker   v-model = "dt"
:attributes ="{ highlight:{color: 'blue',fillMode: 'light'},color: 'red'}"

-->
        <!--div>{{dt}}</div>
        <div><pre>{{days_payed}}</pre></div>
        <div><pre>{{zakazy_payed}}</pre></div-->



    </div>
</template>

<script>

export default {
  name: 'ArchivZakazov',
  props: {
    seatinfo:{
        type: Object,
        required: true
    }
  },
  data: () => ({
    zakazy_payed : {}, 
    days_payed : [],
    dt: null,
    dt_label: null,
    dt_index:null,
    dt_id:null,
    zakazy_day: null,
    busy: null
  }),  
  methods: {
        getArchiveMonth(m, y){
            var dtIdx
            this.busy = true;
            this.$axios.post('/', {"action":"getarchive","m":m,"y":y } )
                .then((response) => {
                if(response.data.success != false && response.data.success != 'false'){
                    this.zakazy_payed = response.data.zakazy_payed
                    this.days_payed = response.data.days_payed

                    dtIdx = null
                    this.days_payed.forEach((d,i) => {
                      if(!dtIdx && new Date(d) > new Date()) dtIdx = i
                    })
                    if(dtIdx) this.gotoDateIndex(dtIdx)
                    else this.gotoDateIndex(this.days_payed.length-1)
                    this.busy = false;

                    //const calendar = this.$refs.calendar
                    //calendar.attributes =  this.getAttributes()

                    //console.log(this.getAttributes())
                    //console.log(calendar)

                    //this.$refs.calendar.attributes.available-dates = this.archive.days_payed
                    //this.$toasted.success(response.data.message).goAway(3000)
                    //this.ordersbusy = false
                }else{

                    this.busy = false;
                    if(response.data.error=="auth" || response.data.error=="init"){
                        //this.needlogin = true
                        this.$emit('needlogin')
                    }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                    }
                }  
                })
                .catch(error => {
                    this.busy = false
                    console.error('There was an error!', error)
                });
        },
        dayClicked(day){
            if(day.isDisabled) return false
            this.dt = day
            this.zakazy_day = this.zakazy_payed[day.id]
            this.dt_index = this.days_payed.indexOf(day.id)
            this.dt_label = day.ariaLabel
            this.dt_id = day.id
            console.log(day)
        },
        gotoDateIndex(idx){
            this.dt_id = this.days_payed[idx]
            this.zakazy_day = this.zakazy_payed[this.dt_id]
            this.dt_index = idx
            //this.dt_label = this.days_payed[idx].dateFormat('dddd D MMMM')

        },
        getAttributes(){
            return this.days_payed.map( d => ({
                    dates: new Date(d),
                    highlight:{
                        color: 'blue',
                        fillMode: 'light'
                    },
                    color: 'red'
                }))
                
            
        },
        getSeatLabel(nam,num){
          if(!nam)return ''
          if(nam.indexOf('#')>-1)
            return nam.replace('#',num)
          else
            return nam
        },
        ticketDownload(fl,code){
          var axiosConfig = {
            /*
            headers: {
              'Content-Type': 'application/json',
            },
            */
            'withCredentials': true,
            'responseType': 'blob'
          }
          this.$axios.post('/', {"action":"download","rn":fl,"code":code}, axiosConfig )
            .then((res) => {
              console.log(res.data.success,res.data.error)
              if(res.data.success != false && res.data.success != 'false'){
                /*
                var FILE = window.URL.createObjectURL(new Blob([res.data]));
                var docUrl = document.createElement('a');
                docUrl.href = FILE;
                docUrl.setAttribute('download', 'ticket_'+fl+'.pdf');
                document.body.appendChild(docUrl);
                docUrl.click(); 
                ////document.body.removeChild(docUrl);
                ////window.URL.revokeObjectURL(FILE);
                console.log(res)
                */
                var FILE = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                window.open(FILE)
              }else{
                if(res.data.error=="init"){
                  this.$emit('needlogin')
                }
              } 
            })
            .catch(error => {
              console.error('There was an error!', error)
            });
        },
        showMsgBoxVisitAnnulConfirm(rn) {
          this.$bvModal.msgBoxConfirm('Ви дійсно бажаєте анулювати візит?', {
                          title: 'Підтвердження',
                          size: 'sm',
                          buttonSize: 'sm',
                          okVariant: 'danger',
                          okTitle: 'Анулювати',
                          cancelVariant: 'success',
                          cancelTitle: 'Відмінити',
                          footerClass: 'p-2',
                          hideHeaderClose: false,
                          centered: true
                        })
                        .then(value => {
                              if(value === true) {
                                return this.annulVisit(rn,'true')
                              }else if(value === false) {
                                return 
                              }
                        })
                        .catch(err => {
                          console.log('Annul visit '+err)
                        })
      
      
        },

        annulVisit(rn,confirm) {
          if(!confirm){
            this.showMsgBoxVisitAnnulConfirm(rn)
            return
          }
          if(confirm === 'false'){
            return
          }
          //this.$emit('globalbusy')
          //this.ordersbusy = true
          this.$axios.post('/', {"action":"annulvisit","rn":rn } )
            .then((response) => {
              if(response.data.success != false && response.data.success != 'false'){
                if(response.data.message && response.data.message.code===777){
                  this.$toasted.error('Цей візит анулювати неможливо').goAway(3000)
                }else{
                  //this.$toasted.success(response.data.message).goAway(3000)
                  this.$toasted.success('Візит було анульовано').goAway(3000)
                  this.getArchiveMonth()
                  this.$emit('ondelete')
                }  
              }else{
                if(response.data.error=="init"){
                  this.$emit('needlogin')
                }else{
                  this.$toasted.error(response.data.message).goAway(3000) 
                }
              }  
            })
            .catch(error => {
              //this.ordersbusy = false
              console.error('There was an error!', error)
            });
          
        },
    
  },
  
  mounted() {
    this.getArchiveMonth()
  },

    computed: {
        availDates(){
            return this.days_payed.map(d => new Date(d))
        },
        attrs(){
            //var now = new Date()
            //return this.days_payed.map(d => ({dates: new Date(d), highlight: true}))
            //
            // ((new Date(d)).getDate() == now.getDate() && (new Date(d)).getMonth() == now.getMonth() && (new Date(d)).getFullYear() == now.getFullYear())?'solid':'outline'
            //(d === now.getFullYear().toString()+'-'+("0" + (now.getMonth()+1)).slice(-2)+'-'+now.getDate().toString())?'solid':'outline'
            //d.substring(8)+'-'+d.substring(5,7)+'-'+d.substring(0,4)
            return this.days_payed.map(d => ({
                dates: new Date(d), 
                highlight: {
                  color: (new Date(d) < new Date())?'gray':'green',
                  fillMode: (this.dt_id === d)?'solid':'outline'
                  
                }
            }))
        },
        /*
        minPage(){
            return {
                min-page [
                    
                        year: (new Date(this.zakazy_days[0])).getFullYear(), 
                        month: (new Date(this.zakazy_days[0])).getMonth()
                        
                ]
            }    
        }
        */
        
    }
}
</script>

<style scoped>


.check{
  padding: 5px;
  background:
    linear-gradient(-45deg, #0000 6px, #fff 7px),
    linear-gradient( 45deg, #0000 6px, #fff 7px),
    linear-gradient(-45deg, #fff calc(100% - 7px), #0000 calc(100% - 8px)),
    linear-gradient( 45deg, #fff calc(100% - 7px), #0000 calc(100% - 8px));
  background-size: 10px 50%;
  background-position: 0 100%, 0 100%, 0 0, 0 0;
  background-repeat: repeat-x;
  font-family: courier;
}

.perforated_check{
  position: relative;
  margin: 3px;
  width: 400px;
  min-height: 200px;
  background: #e2e3de;
  padding: 4px 20px;
  box-shadow: 10px 10px 20px rgb(0 0 0 / 20%);
  font-family: courier;
}

.perforated_check_abon{
  background-color: #fdf3ce;
}

.perforated_check svg{
  position: absolute;
  top: -3px;
  left: 2px;
  width: 100%;
}

.perforated_check table td{
  border-bottom: 1px dashed #c5c5c5;
  vertical-align: top;
}

.perforated_check table {
  margin-bottom:20px;
}

svg.ico-download{
  position: absolute;
  top: 23px;
  left: 21px;
  width: 30px;
}  
/*
svg.ico-visit-success, svg.ico-penalty{
  position: absolute;
  top: 23px;
  right: 21px;
  width: 30px;
}
*/

svg.ico-del-zakaz{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute;
  top: 23px;
  left: 21px;
  width: 30px;
}  

.perforated_check:hover svg.ico-del-zakaz{
  visibility: visible;
  opacity: 1;
}

.shtamp-success, .shtamp-penalty{
  color: #1daa10;
  background-color: transparent;
  border: 3px solid rgb(28, 171, 15, 0.7);
  border-radius: 0.25rem;
  position: absolute;
  padding: 0.5rem 1.25rem;
  transform: rotate(17deg);
  opacity: 0.7;
  right: 18px;
  top: 27px;
}

.shtamp-penalty{
  color: #e22828;
  border: 3px solid rgba(204, 24, 24, 0.663);
}


.comment-canceled{
  color: #b9333f;
  padding-top: 0.5em;
}  


@media (max-width: 575px) {
  .perforated_check{
    width: 100%;
/*    min-height: 200px;*/
  }
  .card-body {
    padding:0;
  }

}

@media (hover: none) {
  svg.ico-del-zakaz{
    visibility: visible;
    opacity: 1;
  }    

}    

/*

.perforated_check{
  position: relative;
  margin: 3px;
  width: 400px;
  min-height: 200px;
  background: #e2e3de;
  padding: 4px 20px;
  box-shadow: 10px 10px 20px rgb(0 0 0 / 20%);
  font-family: courier;
}

.perforated_check svg{
  position: absolute;
  top: -3px;
  left: 2px;
  width: 100%;
}

.perforated_check table td{
  border-bottom: 1px dashed #c5c5c5;
  vertical-align: top;
}

.perforated_check table {
  margin-bottom:20px;
}

.perforated_check:hover svg.ico-del-zakaz{
  visibility: visible;
  opacity: 1;
}

.shtamp-canceled{
  color: #b9333f;
  background-color: transparent;
  border: 3px solid #b9333f;
  border-radius: 0.25rem;
  position: absolute;
  padding: 0.75rem 1.25rem;
  transform: rotate(45deg);
  opacity: 0.7;
}

.comment-canceled{
  color: #b9333f;
  padding-top: 0.5em;
} 


@media (max-width: 575px) {
  .perforated_check{
    width: 100%;
  }
  .card-body {
    padding:0;
  }

}

@media (hover: none) {


}    
*/


</style>
