<template>
    <div class="news">
       
        <b-overlay
            :show="busy"
            rounded
            opacity="0.6"
            spinner
            spinner-variant="bruvis"
        >
            <div v-if="news">
                <b-card v-for="n in news" :key=n class="mb-2">
                    <b-card-text>
                        <div clss="text-right">{{new Date(n.registered) | dateFormat('D MMMM')}}</div>
                        <div v-html="n.message" @click="clickNews($event)" class="newsitem"></div>
                    </b-card-text>
                </b-card>
            </div>    
            <div class="text-center">
                <b-overlay
                    :show="morebusy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="bruvis"
                    class="d-inline-block"
                >
                    <b-button
                        :disabled="morebusy"
                        variant="bruvis"
                        @click="getNews('more')"
                        class="my-4"
                    >
                        Більше новин
                    </b-button>
                </b-overlay>
            </div>    
        </b-overlay>

        <!--b-link @click="jumpTo('0002V','group')"> масаж ніг </b-link>  
        <b-link @click="jumpTo('00038','group')"> Гольф грудень </b-link>  
        <b-link @click="jumpTo('00036','group')"> Теніс ОФП (вт,чт,сб)ЖовтЗал </b-link>  
        <div>
            <b-link @click="jumpTo('00263','worker')"> РУбашкин Л.В. </b-link>  
            <b-link @click="jumpTo('001DC','worker')"> Тарампулька І.В. </b-link>  
        </div-->
    </div>
</template>

<script>

export default {
  name: 'NewsData',
  props: {
    
  },
  data: () => ({
    busy: true,
    morebusy: false,
    news: [],
    pagenum: 1
 

  }),  
  methods: {
        jumpTo(rn,typ){
            this.$emit('jumpTo',rn,typ)
        },
        getNews(mode = null){
            if(mode === 'more'){
                this.pagenum++ 
                this.morebusy = true
            }else
                this.busy = true    
            
            this.$axios.post('/', {"action":"getnews","page":this.pagenum} )
                .then((response) => {
                if(response.data.success != false && response.data.success != 'false'){
                    this.news.push(...response.data.news)
                    this.busy = false
                    this.morebusy = false
                }else{

                    this.busy = false
                    this.morebusy = false
                    if(response.data.error=="auth" || response.data.error=="init"){
                        this.$emit('needlogin')
                    }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                    }
                }  
                })
                .catch(error => {
                    this.busy = false
                    this.morebusy = false
                    console.error('There was an error!', error)
                });
        },

        clickNews(e){
            var el = e.target
            do{
                if(el.id.length==5 && el.className == 'groupjump'){
                    this.$emit('jumpTo',el.id,'group')
                    return false 
                }else if(el.id.length==5 && el.className == 'workerjump'){
                    this.$emit('jumpTo',el.id,'worker')
                    return false 
                }
                el = el.parentNode    
            } while (el.className != 'newsitem')
            return true
        },
        
        //************************************************

        errorMaster(er){
            if(!er) return true
            if(er=="init"){
                if(this.auth){
                    this.$emit('needlogin')
                    console.log('Re LOGIN')
                    return false
                }else{
                    this.$emit('needlogin')
                    //this.init()
                    console.log('Re INIT')
                    return false
                }
            }else if(er=="auth"){
                this.$emit('needlogin')
                console.log('auth - needlogin')
                return false
            }else{
                console.log('Re INKNOWN',er)
                return true
            }
        },


    
  },
  
  mounted() {
    this.getNews()
  },

  created() {

  },


    watch:{
       
       
    },

    computed: {
              
        
    }
}
</script>

<style scoped>




/*
@media (max-width: 575px) {

}


@media (hover: none) {

}    

*/

</style>
