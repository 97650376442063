<template>
    <div class="grouplessons">
        <b-overlay
            :show="busy"
            rounded
            opacity="0.6"
            spinner
            spinner-variant="bruvis"
        >
            <div v-if="!grplessons" class="alert p-4 text-center"><b-spinner variant="bruvis"></b-spinner></div>
            <b-row>
                <b-col md="3">
                    <!--b-form-select
                        v-if="services"
                        v-model="currentService"
                        @change="getSpecialists"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>-- Оберіть послугу --</b-form-select-option>
                        </template>
                        <template v-for="a in services">
                            <b-form-select-option 
                                :value="a.service"
                                :key = a.service
                            >{{a.xservice}}</b-form-select-option>
                        </template>  
                    </b-form-select-->

                    <b-card class="text-center" header-tag="header" v-if="grplessons">
                        <template #header>
                            <h5 class="mb-0 text-left">Групи</h5>
                        </template>
                        <b-list-group>
                            <template v-for="g in grplessons">
                                <b-list-group-item button 
                                    :key="g.rn" 
                                    :active="(gruppa && gruppa.rn===g.rn)" 
                                    @click="setGruppa(g)"
                                >{{g.grpname}}</b-list-group-item>
                            </template>    
                        </b-list-group>
                    </b-card>

                    <!--v-calendar
                        class="mt-2"
                        v-if="gruppa"
                        is-expanded
                        :first-day-of-week="2"
                        ref="calendar" 
                        masks="YYYY-MM-DD" 
                        :locale="{ id: 'uk', firstDayOfWeek: 2, masks: { weekdays: 'WW' } }"
                        :attributes.sync = "attrs"
                        :available-dates.sync="availDates"
                        :highlight ="true"
                        :rows="$screens({ default: 1, xl: 2, lg: 2})"
                        @dayclick="dayClicked"
                        style="margin:0 auto"
                        :min-date = "new Date()"
                    /-->
                    <div v-if="gruppa && gruppa.rn">
                                <b-button v-b-toggle.collapse-info variant="primary" size="sm" class="w-100 my-3">Toggle debug info</b-button>
                                <b-collapse id="collapse-info">
                                    <small>
                                        comments : {{comments}}<br>
                                        datefr : {{new Date(gruppa.datefr) | dateFormat('YYYY-MM-DD')}}<br>
                                        dateto : {{new Date(gruppa.dateto) | dateFormat('YYYY-MM-DD')}}<br>
                                        grpname : {{gruppa.grpname}}<br>
                                        hallobj : {{gruppa.hallobj}}<br>
                                        maxvisitors : {{gruppa.maxvisitors}}<br>
                                        minvisitors : {{gruppa.minvisitors}}<br>
                                        serviceName : {{gruppa.serviceName}}<br>
                                        serviceRN : {{gruppa.serviceRN}}<br>
                                        rn : {{gruppa.rn}}<br>
                                        rp : {{gruppa.rp}}<br>
                                        schm_rn : {{gruppa.schm_rn}}<br>
                                        special : {{gruppa.special}}<br>
                                        xnomenu : {{gruppa.xnomenu}}<br>
                                        xobjname : {{gruppa.xobjname}}<br>
                                        xschmname : {{gruppa.xschmname}}<br>
                                        xspecial : {{gruppa.xspecial}}<br>
                                        xspecialist_mnemos : {{gruppa.xspecialist_mnemos}}<br>
                                        xspecialist_rns : {{gruppa.xspecialist_rns}}<br>

                                    </small>   
                                </b-collapse>  

                                <!--b-button v-b-toggle.collapse-table variant="primary" size="sm">Toggle debug table</b-button>
                                <b-collapse id="collapse-table">
                                    <b-table hover small sticky-header-
                                        v-if="gruppa.days"
                                        :items="gruppa.days" 
                                    >
                                        <template #cell(xday)="data">
                                            <template v-if="data && data.item && data.item.xday">{{ new Date(data.item.xday) | dateFormat('YYYY-MM-DD')}}</template>
                                        </template>
                                    </b-table>
                                </b-collapse-->    


                    </div>


                </b-col>
                <b-col md="9" class="group_container">    

                    <b-card v-if="gruppa" class="text-center mb-2" header-tag="header">
                                <div class="hdr-gruppa">
                                    <div class="text-left">
                                        <h5 v-if="gruppa.grpname" class="mb-3 mt-3">{{gruppa.grpname}}</h5>
                                        <template v-if="gruppa.datefr && gruppa.dateto">
                                            <template v-if="(new Date(gruppa.datefr)).getFullYear() === (new Date(gruppa.dateto)).getFullYear()">
                                                <h6 class="mb-2"><b-icon icon="calendar-week" class="mr-2"></b-icon>Період проведення {{new Date(gruppa.datefr)|dateFormat('D MMMM')}} - {{new Date(gruppa.dateto)|dateFormat('D MMMM')}}</h6>
                                            </template>    
                                            <template v-else>
                                                <h6 class="mb-2"><b-icon icon="calendar-week" class="mr-2"></b-icon>Період проведення {{new Date(gruppa.datefr)|dateFormat('D MMMM YYYY')}} - {{new Date(gruppa.dateto)|dateFormat('D MMMM YYYY')}}</h6>
                                            </template>    
                                        </template>    
                                        <template v-else-if="gruppa.datefr">
                                            <h6 class="mb-2"><b-icon icon="calendar-week" class="mr-2"></b-icon>Діє з {{new Date(gruppa.datefr)|dateFormat('D MMMM')}}</h6>
                                        </template>    
                                        <template v-else-if="gruppa.dateto">
                                            <h6 class="mb-2"><b-icon icon="calendar-week" class="mr-2"></b-icon>Діє по {{new Date(gruppa.dateto)|dateFormat('D MMMM')}}</h6>
                                        </template>

                                        <h6 class="mb-2"><b-icon icon="pin-map" class="mr-2"></b-icon>Послуга: {{gruppa.serviceName}}</h6>
                                        <h6 class="mb-2"><b-icon icon="pin-map" class="mr-2"></b-icon>Місце проведення: {{gruppa.xobjname}}</h6>
                                        <h6 class="mb-2" v-if="gruppa.xspecialist_mnemos"><b-icon icon="person" class="mr-2"></b-icon>Спеціалист: {{gruppa.xspecialist_mnemos}}</h6>
                                        

                                    </div>    
                                    <div class="text-right">
                                        <template v-if="checkedShifts && checkedShifts.length>0">
                                            <!--
                                            <b-form-checkbox 
                                                v-model="useAbon"  
                                                class="m-2 shadow-none"
                                                :disabled="false"
                                            >
                                                Задіяти абонемент
                                            </b-form-checkbox>

                                            <b-form-select
                                                v-if="abons && Object.keys(abons).length>0"
                                                v-model="currentAbon"
                                                @change="changeAbon()"
                                                :disabled="!useAbon"
                                                class="shadow-none"
                                                :class="(useAbon && !currentAbon)?'border-danger mb-2':'mb-2'"
                                                :style="(useAbon && !validDateServiceAbon(abons[currentAbon],checkedShifts,gruppa.serviceRN))?'border-danger;background-color:rgb(255, 114, 114);color: black;':'background-color: #fff;color: black;'"
                                            >
                                                <template v-for="(a,rn) in abons">
                                                    <b-form-select-option 
                                                        :value="a.rn"
                                                        :key = rn
                                                        :style = "(!validDateServiceAbon(a,checkedShifts,gruppa.serviceRN))?'color:red;background-color:white':'color:black;background-color:white'"
                                                    >{{a.xnomenu}}</b-form-select-option>
                                                </template>  
                                            </b-form-select>

                                            -->

                                            <!--Обрано відвідувань: {{checkedShifts.length}}-->
                                            <b-button @click="switchSidebar('rightsidebar','on')" variant="bruvis">
                                                Забронювати
                                            </b-button>
                                        </template>    
                                    </div>    
                                </div>    
                    </b-card>

                    <v-calendar
                        v-if="gruppa"
                        class="custom-calendar max-w-full"
                        :locale="{ id: 'uk', firstDayOfWeek: 2, masks: { weekdays: 'WW' } }"
                        :min-date = "new Date()"
                        :max-date = "new Date(gruppa.dateto)"
                        :masks="masks"
                        :attributes="attrs2"
                        disable-page-swipe
                        is-expanded
                    >
                        <template v-slot:day-content="{ day, attributes }">
                            <div class="flex flex-col h-full z-10 overflow-hidden">
                                <span 
                                    class="day-label text-sm text-gray-900"
                                    :class="((new Date()).getDate() == day.day && (new Date()).getMonth()+1 == day.month)?'font-weight-bold':''"
                                >{{ day.day }}</span>
                                <div class="flex-grow overflow-y-auto overflow-x-auto">
                                    <div
                                    v-for="attr in attributes"
                                    :key="attr.key"
                                    class="text-xs leading-tight p-1 mt-0 mb-1 text-center"
                                    :class="attr.customData.class"
                                    :id="'grp_' + gruppa.rn + '_'+day.id"
                                    @click="(attr.customData.class=='sft-0' || attr.customData.class=='sft-0 shift-checked')?checkShift(gruppa.rn + '_'+day.id):null"
                                    :role="(attr.customData.class=='sft-0' || attr.customData.class=='sft-0 shift-checked')?'button':''"
                                    @selectstart.prevent
                                    >
                                        {{attr.customData.Htmfr}}<span class="sup">{{attr.customData.Mtmfr}}</span>-{{attr.customData.Htmto}}<span class="sup">{{attr.customData.Mtmto}}</span>
                                        <div v-if="attr.customData.class=='sft--2'" class="booked">
                                            <b-iconstack font-scale="1.5">
                                                <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                                                <b-icon stacked icon="check-circle" variant="success"></b-icon>
                                            </b-iconstack>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </v-calendar>
                </b-col> 
            </b-row>     

        </b-overlay>  

        <!-----------SIDEBAR BEGIN----------------------------------------------->

        <b-sidebar 
            id="rightsidebar" 
            ref="rightsidebar" 
            title="Бронювання" 
            right 
            shadow 
            backdrop-variant="dark"
            backdrop
            no-close-on-backdrop-
            sidebar-class="border-left border-bruvis" 
            header-class="bg-victor"
        >
            <template #footer="{ hide }">
              <div class="d-flex d-sm-none bg-transparent align-items-center px-3 py-2">
                <b-button @click="hide" class="bg-bruvis w-100 text-light p-2">Закрити та продовжити бронювання</b-button>
              </div>
            </template>

            <div class="px-3 py-2" v-if="gruppa && checkedShifts && checkedShifts.length>0">

                <b-overlay
                    :show="book_busy"
                    rounded 
                    variant="white"
                    blur="2px" 
                    opacity="0.6"
                    spinner-variant="bruvis"
                    class="d-inline-block w-100"
                >
                <div id="booking">
                      

                    <b-alert variant = "white" show fade class="text-left">
                         <h5 v-if="gruppa.grpname" class="mb-2">{{gruppa.grpname}}</h5>
                        <!--
                        <h5>{{specialist.xworker}}</h5>
                        <h5>{{hallNames[selectHall]}}</h5>
                        <div v-if="currentService || (currentServicesList && currentServicesList.length===1) || (!currentService && currentServicesList && currentServicesList.length===1)">Послуга: {{services[currentServicePost]}}</div>
                        <div>{{new Date(ondate) | dateFormat('dddd D MMMM YYYY')}}</div>
                        <div><b-icon icon="clock-history" class="mx-1" shift-v="-1"/> {{selectFr}}-{{selectTo}}</div>
                        -->

                    </b-alert>  

                    <template v-if="checkedShifts.length>0"><!--@dismissed="checkSeat(sts)"-->
                        <b-alert 
                            variant = "success"
                            v-for="sft of checkedShifts" :key="sft"
                            show
                            dismissible-
                            fade
                            class="text-left"
                        >
                            {{new Date(sft.split('_')[1]) | dateFormat('dddd D MMMM YYYY')}}
                        </b-alert>
                  
                    </template> 


                    <template v-if="!visitor || !visitor.visitorRN">
                        <div class="text-left">
                            <b-form-input
                                id="booking_email"
                                v-model="booking_email"
                                type="email"
                                placeholder="Email"
                                required
                                class="mb-3"
                            ></b-form-input>
                            <b-form-input
                                id="booking_name"
                                v-model="booking_name"
                                placeholder="Введіть ваше ПІБ"
                                required
                                class="mb-3"
                            ></b-form-input>
                        </div>    
                    </template>

                    <template v-else-if="abons && Object.keys(abons).length>0">    
                        <div v-if="checkedShifts.length>0" class="text-left">

                            <b-form-checkbox 
                                v-model="useAbon"  
                                class="m-2 shadow-none"
                                :disabled="false"
                            >
                                Задіяти абонемент
                            </b-form-checkbox>

                                <b-collapse v-model="useAbon" class="p-0 m-0">

                                    <b-form-select
                                        v-if="abons && Object.keys(abons).length>0"
                                        v-model="currentAbon"
                                        @change="changeAbon()"
                                        :disabled="!useAbon"
                                        class="shadow-none"
                                        :class="(useAbon && !currentAbon)?'border-danger mb-2':'mb-2'"
                                        :style="(useAbon && !validDateServiceAbon(abons[currentAbon],checkedShifts,gruppa.serviceRN))?'border-danger;background-color:rgb(255, 114, 114);color: black;':'background-color: #fff;color: black;'"
                                    >
                                        <template v-for="(a,rn) in abons">
                                            <b-form-select-option 
                                                :value="a.rn"
                                                :key = rn
                                                :style = "(!validDateServiceAbon(a,checkedShifts,gruppa.serviceRN))?'color:red;background-color:white':'color:black;background-color:white'"
                                            >{{a.xnomenu}}</b-form-select-option>
                                        </template>  
                                    </b-form-select>

                                    <b-form-checkbox 
                                        v-model="allLeftDays"  
                                        class="m-2 shadow-none"
                                        :disabled="false"
                                    >
                                        всі дні до кінця дії групи
                                    </b-form-checkbox>
                                </b-collapse>    



                        </div>
                        
                    </template>

                                  

                </div>

                <template>
                  <b-collapse v-model="isVisibleGoPay" class="p-0 m-0">
                    <div class="m-0 p-0 text-left">
                        <b-form-checkbox v-model="readyToPay"  class="m-2">перейти до оплати</b-form-checkbox>  
                    </div>  
                  </b-collapse>  

                  <b-button 
                    block
                    @click="goBook()"
                    variant="success"
                    class="mt-2 p-2 shadow-none"
                    :disabled="(useAbon && (!currentAbon || !validDateServiceAbon(abons[currentAbon],checkedShifts,gruppa.serviceRN))) ||
                               ((!visitor || !visitor.visitorRN) && (!booking_email || !booking_name))"
                  >
                    Забронювати
                    <b-icon aria-hidden="true" :icon="(!useAbon || !currentAbon)?'cart-plus':'cart-check'" variant="white" class="mx-1"></b-icon>
                  </b-button>

                  

                </template>  

              </b-overlay>
            </div>
        </b-sidebar>



    </div>
</template>

<script>

export default {
  name: 'GroupLessons',
  props: {
     settings: {
      type: Object,
      required: true
    },
    abons: {
      type: Object,
      required: false
    },
    visitor: {
      type: Object,
      required: false
    },
    need2update: {
      type: Object,
      required: false
    },
    gruppa2open: {
      type: Object,
      required: false
    },
    
  },
  data: () => ({
    busy: true,
    book_busy: false,
    services: null,
    currentService: null,
    fields: [],
    grplessons: null,
    fields_grplessons: [],
    gruppa: null,
    dt_id: null,
    fields_gruppa:[],
    tabIndex:0,
    checkedShifts: null,
                            
    masks: {
        weekdays: 'WWW',
    },

    useAbon: false,
    currentAbon: null,
    booking_email: null,
    booking_name: null,
    readyToPay: true,
    allLeftDays: false,
    states: null
  }),  
  methods: {
        
        
        setGruppa(item) {
          this.gruppa = null 
          this.$nextTick().then(this.gruppa = item)
          this.checkedShifts = null
          this.useAbon = false
          this.currentAbon = null
          //this.$nextTick().then(this.$refs.calendar.move(new Date()))
        },

        jumpToGruppa(rn) {
          var grp  
          this.grplessons.forEach((g)=>{
            if(g.rn==rn){
                grp=g
            }
          })
          if(grp) this.setGruppa(grp)
          else this.setGruppa(this.grplessons[0])
        },

        getGroupLessons(){
            this.busy = true;
            this.$axios.post('/', {"action":"getgrouplessons"} )
                .then((response) => {
                if(response.data.success != false && response.data.success != 'false'){
                    if(response.data.grplessons){
                        this.grplessons = response.data.grplessons
                        if(!this.gruppa2open)
                            this.setGruppa(this.grplessons[0])
                        else{
                            this.jumpToGruppa(this.gruppa2open)
                            this.$emit('clearJump')    
                        }    
                    }    
                    this.busy = false;

                }else{

                    this.busy = false;
                    this.errorMaster(response.data.error)
                    /*
                    if(response.data.error=="auth" || response.data.error=="init"){
                        this.$emit('needlogin')
                    }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                    }
                    */
                }  
                })
                .catch(error => {
                    this.busy = false
                    console.error('There was an error!', error)
                });
        },
        /*
        dayClicked(day){
            //if(day.isDisabled) return false
            //this.dt = day
            //this.zakazy_day = this.zakazy_payed[day.id]
            //this.dt_index = this.days_payed.indexOf(day.id)
            //this.dt_label = day.ariaLabel
            this.dt_id = day.id
            console.log(day.id)
        },
        */
        floatToTime(t){
          var tmp = t-Math.trunc(t)
          var out = (Math.trunc(t)).toString() + ':'
          out += (tmp>0)?(Math.round(tmp*100)).toString():'00'
          return out
        },
        checkShift(e){
            if(!this.checkedShifts) this.checkedShifts=[]
            let n = this.checkedShifts.indexOf(e)
            if(n===-1) {
                //this.checkedShifts.push(e) // это убираем , пока мы решили выбирать один день
                //this.checkedShifts.sort()  //

                this.checkedShifts=[e] // это нужно пока мы решили выбирать только один день
            }else 
                if(this.checkedShifts.length>1) {
                    //this.checkedShifts = this.checkedShifts.slice(0, n+1)
                    this.checkedShifts = this.checkedShifts.slice(0, n).concat(this.checkedShifts.slice(n+1))    
                }else this.checkedShifts=[]
            console.log(this.checkedShifts)
        },

        changeAbon(){
            this.useAbon = (this.currentAbon!=null)
        },
        //validDateServiceAbon(a,checkedShifts,gruppa.serviceRN)
        validDateServiceAbon(abn,checkedList,service){
            if(!abn || !checkedList) return true
            if(abn.serviceRN != service) return false
            var dt, valid = true
            checkedList.forEach(sft => {
                dt = sft.split('_')[1]
                if(valid && abn.datefr && new Date(dt) < new Date(abn.datefr)) valid=false
                if(valid && abn.dateto && new Date(dt) > new Date(abn.dateto)) valid=false
            });
            if(valid && !this.currentAbon) {
                this.currentAbon = abn.rn //первый валидный
                this.useAbon = true
            }    
            return valid
        },

        switchSidebar(el,action){
            switch (action){
                case 'on':
                    if(document.getElementById(el) && document.getElementById(el).style.display=='none')
                        this.$root.$emit('bv::toggle::collapse', el)
                        //console.log('-----on')
                    break
                case 'off':    
                    if(document.getElementById(el) && document.getElementById(el).style.display!='none')
                        this.$root.$emit('bv::toggle::collapse', el)
                        //console.log('-----off')
            }
        },

        isLogined(){
            //return (this.auth && this.auth.visitor && this.auth.visitor.visitorRN)
            return (this.visitor && this.visitor.visitorRN)
        },

        /*********************************************** */    

        goBook(){
            if(!this.checkedShifts){
                console.error('List is empty')
                return false
            }

            let attr = this.checkedShifts[0].split('_')
            let groupRN = attr[0]
            let onDate  = attr[1]

            if(!groupRN ||!onDate) return

            this.book_busy = true            
            
            var postData = {
                "action"  :"bookgroup",
                "date"    : onDate,
                "group"   : groupRN,
                "onemode" : !this.allLeftDays,  
                "abon"    : (this.currentAbon && this.useAbon)?this.currentAbon:null,

                "email"   : (!this.isLogined())?this.booking_email:null,
                "name"    : (!this.isLogined())?this.booking_name:null,
                "groupname"   : (this.gruppa)?this.gruppa.grpname:null,  
                //"timefr"    : this.selectFr,
                //"timeto"    : this.selectTo,
                "xservice"  : (this.gruppa)?this.gruppa.serviceName:null,  
                "hallobj"  : (this.gruppa)?this.gruppa.xobjname:null
            }

            var axiosConfig = {
                headers: {
                'Content-Type': 'application/json',
                },
                'withCredentials': true
            }
            this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success != false  && response.data.success != 'false'){

                        //this.updateStates()                         // ПЕРЕЧИТАТИ СТЕЙТИ!!!!
                        //убрат тут одновление и прописал ниже, для абонемента, т.к. для тикетов обновится из корня по ватчеру

                        //console.log(response.data)
                        //this.init()
                        //console.log('init')

                        if(this.currentAbon && this.useAbon) {
                            //абонемент
                            this.updateStates()
                            if(response.data.response[0].visitRN){
                                this.$toasted.success('Бронювання із застосуванням абонементу було успішним!').goAway(2000)
                                this.$toasted.success('Кількість заброньованих візитів: ' + response.data.response[0].visitAddedCount).goAway(2000)
                                this.checkedShifts = []
                                this.switchSidebar('rightsidebar','off')
                            }else
                                this.$toasted.error('Бронювання із застосуванням абонементу не було успішним!').goAway(2000)    
                                
                            this.book_busy = false
                            
                            ////this.getAbonsVisits()
                        }else /*if(response.data.liqpay.data && response.data.liqpay.signature)*/{

                            this.$emit('onupdatebasket')    
                            this.$toasted.success('Бронювання було успішним! Квитки необхідно оплатити').goAway(2000)
                            this.book_busy = false
                            this.checkedShifts = []
                            this.switchSidebar('rightsidebar','off')
                            
                            if(this.readyToPay && !this.useAbon) this.$emit('readytopay')    

                            /*

                                if(response.data.errormessage){  
                                    this.$toasted.error('Увагf! Не вдалося забронювати відвідування на наступні дні : '+response.data.errormessage).goAway(6000)
                                }

                                this.unpayedticketsTmp = this.unpayedtickets

                                response.data.checks.forEach((c) =>{  
                                    c.shift   = this.currentShift //обратить внимсан7ие!!!
                                    this.unpayedticketsTmp.push(c)
                                })

                                this.unpayedtickets = this.unpayedticketsTmp
                                this.book_busy = false    
                                this.checkedSeats = []
                                                
                                if(this.readyToPay && this.unpayedtickets.length>0){
                                    this.$bvModal.show('unpayedtickets-modal')
                                }  
                            */
                           
                        }
                    
                    }else{
                        this.book_busy = false

                        if (!this.errorMaster(response.data.error)){
                            console.log('aga')
                            return
                        }     

                        if(response.data.code) console.log('Помилка № '+response.data.code)

                        if(response.data.code === 23){
                            this.$toasted.error('Нажаль по цьому абонементу вже використано візити:(').goAway(6000)
                            
                            //this.book_busy = true
                            //this.updateStates(false)
                            //this.book_busy = false  
                            
                            
                        }else if(response.data.code === 8){
                            this.$toasted.error('Не введено електрону адресу!:(').goAway(6000)

                        }else{  
                            this.$toasted.error('Нажаль забронювати це вже неможливо:(').goAway(6000)

                            if(response.data.message)
                                this.$toasted.error(response.data.message).goAway(4000)
                            else if(response.data.error)
                                this.$toasted.error(response.data.error).goAway(4000)
                            else  
                                this.$toasted.error('Error').goAway(2000)  


                            this.book_busy = true

                            this.updateStates()  // !!!!!!! ПЕРЕЧИТАТЬ СТЕЙТЫ!!!!

                            this.book_busy = false 
                        }  
                    }  
                })
                .catch(error => {
                    console.error('There was an error!', error)
                    this.book_busy = false
                    this.$toasted.error('Error').goAway(2000)
                });  
            },

        updateStates(){

            let gr = this.gruppa
            if(!gr) return true
//            console.log('Worker',sp.worker,sp.hallobj[0].scheme[0].days[0].shiftRN);

            var postData = {
                "action"  :"getgroupstates",
                "group" : gr.rn
            }

            var axiosConfig = {
                headers: {
                'Content-Type': 'application/json',
                },
                'withCredentials': true
            }

            this.busy = 1
            ///this.states = null НЕНАДО СБРАСЫВАТЬ

            this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
                .then((response) => {
                    if(response.data.success != false  && response.data.success != 'false'){
                        if(response.data.states) this.states = response.data.states
                        // ПЕРЕЧИТАТИ СТЕЙТИ!!!!
                        this.busy = false
                   
                    }else{
                        this.busy = false
                        if (!this.errorMaster(response.data.error)){
                            console.log('aga')
                            return
                        }     
                    }  
                })
                .catch(error => {
                    console.error('There was an error!', error)
                    this.busy = false
                    this.$toasted.error('Error').goAway(2000)
                });  
        },    

        getShiftClass(dt){
            if(new Date(dt) < new Date()) return 'sft--0'
            if(this.checkedShifts && this.checkedShifts.indexOf(this.gruppa.rn+'_'+dt)>-1) return 'sft-0 shift-checked'
            if(this.states && this.gruppa && this.states[this.gruppa.rn])
                switch(this.states[this.gruppa.rn][dt]){
                    case 0:
                        //свободно
                        return 'sft-0'
                    case 1:
                        //занято
                        return 'sft--1'
                    case 2:
                        //забронировано мною
                        return 'sft--2'
                    default:
                        return 'sft---1'        
                }
        },



    //************************************************

        errorMaster(er){
            if(er=="init"){
                if(this.auth){
                this.$emit('needlogin')
                console.log('Re LOGIN')
                }else{
                this.$emit('needlogin')
                //this.init()
                console.log('Re INIT')
                }
            }if(er=="auth"){
                this.$emit('needlogin')
            }else{
                console.log('Re INKNOWN',er)
            }
            return true
        },


    
  },
  
  mounted() {
    this.busy = true
    this.getGroupLessons()
    //if(this.gruppa2open)

//    if(this.grplessons)this.setGruppa(this.grplessons[0])
    this.busy = false
  },

  created() {

  },

  watch: {
    gruppa: function(sp){
            console.log('Changed! Gruppa=',sp)
               if(!sp) return true
               this.updateStates()
            },
    need2update: function(nw, o){
                if(nw && !o){
                    this.$emit("onUpdateStates")
                    this.updateStates()
                }
            },

  },

    computed: {
        /*
        availDates(){
            return this.gruppa.days.map(d => new Date(d.xday))
        },

        attrs(){
            //var now = new Date()
            //return this.days_payed.map(d => ({dates: new Date(d), highlight: true}))
            //
            // ((new Date(d)).getDate() == now.getDate() && (new Date(d)).getMonth() == now.getMonth() && (new Date(d)).getFullYear() == now.getFullYear())?'solid':'outline'
            //(d === now.getFullYear().toString()+'-'+("0" + (now.getMonth()+1)).slice(-2)+'-'+now.getDate().toString())?'solid':'outline'
            //d.substring(8)+'-'+d.substring(5,7)+'-'+d.substring(0,4)
            return this.gruppa.days.map(d => ({
                dates: new Date(d.xday), 
                highlight: {
                  color: (new Date(d.xday) < new Date())?'gray':'green',
                  fillMode: (this.dt_id === d.xday)?'solid':'outline'
                  
                }
            }))
        },
        */        
        attrs2(){
            return this.gruppa.days.map(d => ({
                key: d.xday,
                dates: new Date(d.xday), 
                customData: {
                    title: d.xtimefr+'-'+d.xtimeto,
                    Htmfr: d.xtimefr.split(':')[0],
                    Mtmfr: d.xtimefr.split(':')[1],
                    Htmto: d.xtimeto.split(':')[0],
                    Mtmto: d.xtimeto.split(':')[1],
                    text: d.xobjname + '-' + d.xspecialist_mnemos,
                    class: this.getShiftClass(d.xday)
                }//(new Date(d.xday) > new Date())?'bg-bruvis text-white':'bg-secondary text-white',
            }))
        },


        isVisibleGoPay: function(){
            return !this.useAbon || !this.currentAbon
        }   
     
        
    }
}
</script>

<style >

.sup{
    font-size: 0.7em;
    vertical-align: text-top;
    margin-left: 2px;
}

.group_container{
    overflow: auto;
}

.raspisanie{
  display: flex;
  overflow: auto;
}

.raspisanie-col {
  border: 1px solid #e0e0e0;
}

.raspisanie-day{
  background-color: #003366!important;
  color: #fff!important;
  text-align: center;
}

.hdr-gruppa{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
@media (max-width: 575px) {
    .hdr-gruppa{
        align-items: stretch;
        flex-direction: column;
    }
}

.sft--0 {
    color: #adb5bd;
    background-color: #eee;
    border-radius: 10px;
    border-color: #343a40;
    cursor: no-drop;
    border: 0px;
    padding: 8px !important;
}

.sft--1 {
    color: #666;
    background-color: #aaa;
    border-radius: 10px;
    border-color: #343a40;
    cursor: no-drop;
}

.sft-0{
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-radius: 10px;
    border: 0px;
    padding: 8px !important;
}


.sft--2 {
    border: 4px solid;
    padding: 4px !important;
    border-color: #45a011;
    border-radius: 10px;
    position: relative;
}

.booked{
    position: absolute;
    top: -19px;
    right: -3px;
}


.shift-checked{
    border: 4px solid;
    padding: 4px !important;
}








/*
@media (max-width: 575px) {

}


@media (hover: none) {

}    

*/

.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
}
.custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
.custom-calendar.vc-container .vc-weeks {
  padding: 0;
}
.custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}
.custom-calendar.vc-container .vc-day {
  padding: 0 5px 3px 5px;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: white;
}
.custom-calendar.vc-container .vc-day.weekday-1, .custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}
.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}
.custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: var(--day-border-highlight);
}
.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}
.custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}

</style>
