<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
        <validation-provider
          name="Ім'я"
          :rules="{required: true, min: 1, max: 16}"
          v-slot="validationContext"
        ><!--          mode="lazy"-->
          <b-form-group id="example-input-group-1" label="Ім'я" label-for="firstname">
            <b-form-input
                id="firstname"
                name="firstname"
                ref="firstname"
                v-model="ofirstname"
                type="text"
                :state="getValidationState(validationContext)"
                aria-describedby="firstname-live-feedback"
                autocomplete="current-password"
                spellcheck="false" 
                autocorrect="off" 
                autocapitalize="off" 
            ></b-form-input>
            <b-form-invalid-feedback id="firstname-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          name="Призвіще"
          :rules="{required: true, min: 1, max: 16}"
          v-slot="validationContext"
        ><!--          mode="lazy"-->
          <b-form-group id="example-input-group-2" label="Призвіще" label-for="surname">

            <b-form-input
                id="surname"
                name="surname"
                ref="surname"
                v-model="osurname"
                type="text"
                :state="getValidationState(validationContext)"
                aria-describedby="firstname-live-feedback"
                autocomplete="current-password"
                spellcheck="false" 
                autocorrect="off" 
                autocapitalize="off" 
            ></b-form-input>
            <b-form-invalid-feedback id="surname-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-overlay
            :show="busy"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="bruvis"
            class="d-inline-block"
            @hidden="onHidden"
            >
            <b-button
                ref="button"
                type="submit"
                :disabled="busy || (firstname==ofirstname && surname==osurname)"
                variant="bruvis"
            >
                Записати
            </b-button>
        </b-overlay>
        <b-button class="ml-2" @click="resetForm()" :disabled="firstname==ofirstname && surname==osurname">Повернути</b-button>
        <b-button class="ml-2" @click="resetForm(), $emit('oncancel')">Відміна</b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<style>

</style>

<script>
export default {
  name: 'ChangeName', 
  props: {
    firstname: {
      type: Object,
      required: true
    },
    surname: {
      type: Object,
      required: true
    }
  }, 
  data() {
    return {
      busy: false,   
      ofirstname: null,
      osurname: null,
    };
  },
  mounted(){
    this.ofirstname=this.firstname,
    this.osurname=this.surname

  },  
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.ofirstname=this.firstname
      this.osurname=this.surname
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onSubmit() {
      this.changeName(this.ofirstname,this.osurname)
    },
    onHidden() {
        this.$refs.button.focus()
    },
    changeName(firstname,surname){
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json'
        },
        'withCredentials': true
      }
      this.busy = true 
      this.$axios.post('/', {"action":"changename","firstname":firstname,"surname":surname}, axiosConfig )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != "false"){
                      this.$toasted.success('Інформацію змінено').goAway(6000)
                      this.$emit('onsuccess')
                      this.busy = false 
                      this.resetForm()
                    }else{
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        //this.needlogin = true
                        this.$toasted.error('Час сессії вичерпано').goAway(4000)
                      }else if(response.data.message != null){
                        this.$toasted.error(response.data.message).goAway(4000)
                      }else{
                        this.$toasted.error('Помилка:(').goAway(4000)
                      } 
                      this.busy = false  
                    }
                  })
                  .catch(error => {
                    this.busy = false  
                    console.error('There was an error!', error)
                    this.$toasted.error('Помилка: '+error).goAway(4000)
                  });
    }
  }
};
</script>