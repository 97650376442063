<template>
    <div id="login">
        <!----------------------------------------------------->
        <div style="" class="login-modal-body" v-if="step==='first'">
            <div v-bind:class="{ error: emptyFields }">
                <div class="form-group login-form">
                    <b-overlay
                      :show="busy"
                      rounded 
                      variant="white"
                      blur="2px" 
                      opacity="0.6"
                      spinner-variant="bruvis"
                      class="d-inline-block w-100"
                    >
                      <input v-model="nameLogin" type="text" class="form-control my-2" placeholder="E-mail" required>
                      <input v-model="passwordLogin" type="password" class="form-control my-2" placeholder="Пароль" required>
                      <div v-if="error_login_msg" class="text-danger my-2">{{error_login_msg}}</div>
                      <b-button class="mt-3 mb-4 shadow-none" block @click="doLogin" :disabled="busy || nameLogin==='' || passwordLogin===''">Увійти</b-button>
                      <template v-if="!settings || !settings.disable_registration">
                        <div><b-link @click="step='checkemail'">Новий користувач</b-link></div>
                        <div><b-link>Забув пароль</b-link></div>
                      </template>  
                    </b-overlay>  
                </div>
            </div>

             <!--iv id="zoom-controls-3">
              <b-iconstack  font-scale="1.5" rotate="45" role="button">
                <b-icon icon="square-fill" variant="light" class="maskicon2" ></b-icon>
                <b-icon icon="square-fill" variant="light" class="maskicon2" shift-v="18"></b-icon>
                <b-icon icon="square-fill" variant="light" class="maskicon2" shift-h="18"></b-icon>
                <b-icon icon="square-fill" variant="light" class="maskicon2" shift-v="-18"></b-icon>    

                <b-icon :class="(hovered=='zoom-move-up')?'hovered':''" icon="arrow-down-left-square" rotate="90"></b-icon>
                <b-icon :class="(hovered=='zoom-move-right')?'hovered':''" icon="arrow-down-left-square" shift-h="18" rotate="180"></b-icon>
                <b-icon :class="(hovered=='zoom-move-down')?'hovered':''" icon="arrow-down-left-square" shift-h="18" shift-v="-18"  rotate="-90"></b-icon>
                <b-icon :class="(hovered=='zoom-move-left')?'hovered':''" icon="arrow-down-left-square" shift-v="-18"></b-icon>

                <b-icon icon="square-fill" variant="light" class="maskicon" @click.stop="svgMove('up')" @mouseover.prevent="hovered='zoom-move-up'" @mouseleave="hovered=null"></b-icon>
                <b-icon icon="square-fill" variant="light" class="maskicon" shift-h="18" @click.stop="svgMove('right')" @mouseover.prevent="hovered='zoom-move-right'" @mouseleave="hovered=null"></b-icon>
                <b-icon icon="square-fill" variant="light" class="maskicon" shift-h="18" shift-v="-18"  @click.stop="svgMove('down')" @mouseover.prevent="hovered='zoom-move-down'" @mouseleave="hovered=null"></b-icon>
                <b-icon icon="square-fill" variant="light" class="maskicon" shift-v="-18" @click.stop="svgMove('left')" @mouseover.prevent="hovered='zoom-move-left'" @mouseleave="hovered=null"></b-icon>  
              </b-iconstack>
            </div--> 




            <div class="small text-center powered">
                Powered by <a target="_blank" href="https://jsolutions.ua/ua/avtomatizatciya-korporativnogo-pitaniya"><b-img src="/img/logo_jsol.png" width="71"></b-img></a>
            </div> 
        </div>            
        <!-----1--------------------------------------------------->
        <template v-if="step==='checkemail'">
            <b-card title="Крок 1" sub-title="В системі використовується E-mail в якості ідентифікатора користувача">
                <b-card-text>
                    <validation-observer ref="observer1" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
                            <validation-provider
                            name="E-mail"
                            :rules="{ required: true , regex: /^[a-zA-Z0-9][a-zA-Z0-9-_\.]+@([a-zA-Z]|[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9])\.[a-zA-Z0-9]{2,10}(?:\.[a-zA-Z]{2,10})?$/, EmailIsFree: true}"
                            v-slot="validationContext"
                            mode="lazy"
                            >
                                <b-form-group id="example-input-group-1" label="Введіть свій актуальний E-mail" label-for="email">
                                    <b-form-input
                                        id="email" name="email"
                                        ref="email"
                                        v-model.lazy="email"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="email-live-feedback"
                                        autocomplete="new-password"
                                        spellcheck="false" 
                                        autocorrect="off" 
                                        autocapitalize="off" 
                                    />
                                    <b-form-invalid-feedback id="email-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <b-row>
                                <b-col class="text-center">
                                    <b-overlay
                                        :show="emailbusy" rounded opacity="0.6" spinner-small
                                        spinner-variant="bruvis" class="d-inline-block"
                                        @hidden="onHidden"
                                    >
                                        <b-button
                                            ref="button" type="submit"
                                            :disabled="emailbusy" variant="bruvis"
                                        >
                                            Отримати код
                                        </b-button>
                                    </b-overlay>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-card-text>
            </b-card>
        </template>
        <!----2----------------------------------------------------------->
        <template v-if="step==='entercode'">
            <b-card title="Крок 2" :sub-title="'Введіть у форму перевірочний код, що було вислано на адресу '+ email ">
                <b-card-text>
                    <!--validation-observer ref="observer2" v-slot="{ handleSubmit }"-->
                        <b-form  autocomplete="off" @submit.stop.prevent="checkEmailCode()"><!--@submit.stop.prevent="handleSubmit(onSubmitCode)"-->
                            <!--validation-provider
                            name="'Перевірочний код'"
                            :rules="{required: true}"
                            v-slot="validationContext"
                            -->
                                <b-form-group id="emailcode-input-group-1" label="" label-for="emailcode">
                                    <b-input-group :prepend="emailprecode+' -'" class="mb-2 mr-sm-2 mb-sm-0">
                                        <b-form-input
                                            id="emailcode" name="emailcode" ref="emailcode"
                                            v-model.lazy="emailcode" type="text"
                                            autocomplete="off" spellcheck="false" 
                                            autocorrect="off" autocapitalize="off"
                                        />
                                        <!--b-form-input
                                            id="emailprecode" name="emailprecode" ref="emailprecode"
                                            v-model="emailprecode" type="hidden"
                                        /-->
                                    </b-input-group>
                                    <!-- 
                                    <b-row>
                                        <b-col class="col-6">
                                            <b-form-input
                                                id="emailprecode" name="emailprecode" ref="emailprecode"
                                                v-model="emailprecode" type="text"
                                                readonly
                                            />
                                        </b-col>    
                                        <b-col class="col-6">
                                            <b-form-input
                                                id="emailcode" name="emailcode" ref="emailcode"
                                                v-model.lazy="emailcode" type="text"
                                                autocomplete="new-password"
                                                spellcheck="false" 
                                                autocorrect="off" 
                                                autocapitalize="off" 
                                            />
                                        </b-col>    
                                    -->
                                    
                                    <!--b-form-invalid-feedback id="emailcode-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback-->
                                </b-form-group>
                            <!--/validation-provider-->

                            <b-row>
                                <b-col class="text-left">
                                    <b-button ref="button"  
                                        variant="bruvis"
                                        @click="step='checkemail',emailcode='',emailprecode=''"
                                    >   
                                        <b-icon icon="chevron-double-left" class="mr-2"/>Повернутися
                                    </b-button>
                                </b-col>
                                <b-col class="text-right">
                                    <b-overlay
                                        :show="emailcodebusy" rounded opacity="0.6" spinner-small
                                        spinner-variant="bruvis" class="d-inline-block"
                                        @hidden="onHidden"
                                    >
                                        <b-button
                                            ref="button" type="submit"
                                            :disabled="emailcodebusy || !emailcode" variant="bruvis"
                                        >
                                            Підтвердити
                                        </b-button>
                                    </b-overlay>
                                </b-col>
                            </b-row>
                        </b-form>
                    <!--/validation-observer-->
                </b-card-text>
            </b-card>
        </template>
        <!----3----------------------------------------------------------->
        <template v-if="step==='mainform'">
            <b-card title="Крок 3" sub-title="Заповніть реєстраційну форму">
                <b-card-text class="mt-4">
                    <validation-observer ref="observer3" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(onSubmitRegform)" autocomplete="off">
                            <b-row class="my-2">
                                <b-col sm="4">
                                    <label for="emailcode2">E-mail</label>
                                </b-col>    
                                <b-col sm="8">
                                    <b-form-input
                                        name="emailcode2"
                                        v-model="email" type="text"
                                        readonly
                                    />
                                </b-col>
                            </b-row>
                            <b-row class="my-2">
                                <b-col sm="4">
                                    <label for="phone">Телефон</label>
                                </b-col>    
                                <b-col sm="8" :class="(phonealert)?'phone_not_valid':''">
                                    <!--validation-provider
                                        name="Телефон"
                                        :rules="{required: true, regex:/^\d+\s*\d+$/, min:7 }"
                                        mode="passive"
                                        v-slot="validationContext"
                                    >
                                        <b-form-input
                                            id="phone" name="phone" ref="phone"
                                            v-model="regdata.phone" type="text"
                                            :state="getValidationState(validationContext)"
                                            aria-describedby="phone-live-feedback"
                                            autocomplete="new-password" spellcheck="false" 
                                            autocorrect="off" autocapitalize="off" 
                                        />
                                        <b-form-invalid-feedback id="phone-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </validation-provider-->
                                    <vue-tel-input 
                                        v-model="regdata.phone"
                                        v-bind="vueTel.props"
                                        @validate="validatePhone"
                                    ></vue-tel-input>
                                </b-col>
                            </b-row>
                            <!------------>
                            <!--b-row class="my-2">
                                <b-col sm="4">
                                    <label for="viber">Viber</label>
                                </b-col>    
                                <b-col sm="8"-->
                                    <!--validation-provider
                                        name="Viber"
                                        :rules="{ regex:/^\d+$/ }"
                                        v-slot="validationContext"
                                        mode="lazy"
                                    >
                                        <b-form-input
                                            id="viber" name="viber" ref="viber"
                                            v-model.lazy="regdata.viber" type="text"
                                            :state="getValidationState(validationContext)"
                                            aria-describedby="viber-live-feedback"
                                            autocomplete="new-password"
                                            spellcheck="false" autocorrect="off" 
                                            autocapitalize="off" 
                                        />
                                        <b-form-invalid-feedback id="viber-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </validation-provider-->
                                    <!--vue-tel-input 
                                        v-model="regdata.viber"
                                        v-bind="vueTel.props"
                                    ></vue-tel-input-->
                                <!--/b-col>
                            </b-row-->
                            <validation-observer>
                            <b-row class="my-2">
                                <b-col sm="4">
                                    <label for="pass">Пароль</label>
                                </b-col>    
                                <b-col sm="8">    
                                  <validation-provider 
                                    name="Пароль" 
                                    :rules="{required: true, min: 1, max: 16}" 
                                    :vid="regdata.pass" v-slot="validationContext"
                                >
                                    <b-input-group>    
                                        <b-form-input
                                            id="newpass"
                                            name="newpass"
                                            ref="newpass"
                                            v-model="regdata.pass"
                                            :type="(showpass)?'text':'password'"
                                            :state="getValidationState(validationContext)"
                                            aria-describedby="newpass-live-feedback"
                                            autocomplete="off"
                                            spellcheck="false" 
                                            autocorrect="off" 
                                            autocapitalize="off" 
                                        ></b-form-input>
                                        <b-input-group-append is-text>
                                            <b-icon @click="showpass=!showpass" :icon="(showpass)?'eye-fill':'eye-slash-fill'" role="button"></b-icon>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-form-invalid-feedback id="newpass-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </validation-provider>    
                            </b-col>
                        </b-row>
                        <b-row class="my-2">
                                <b-col sm="4">
                                    <label for="pass">Підтвердження</label>
                                </b-col>    
                                <b-col sm="8">  
                                    <validation-provider name="Підтвердження пароля" 
                                        :rules="{required: true, confirmed: regdata.pass}" v-slot="validationContext"
                                    >
                                        <b-input-group>    
                                            <b-form-input
                                                id="newpass2"
                                                name="newpass2"
                                                ref="newpass2"
                                                :type="(showpass)?'text':'password'"
                                                v-model="regdata.pass2"
                                                :state="getValidationState(validationContext)"
                                                aria-describedby="newpass2-live-feedback"
                                                autocomplete="off"
                                                spellcheck="false" 
                                                autocorrect="off" 
                                                autocapitalize="off"                 
                                            ></b-form-input>
                                            <b-input-group-append is-text>
                                                <b-icon @click="showpass=!showpass" :icon="(showpass)?'eye-fill':'eye-slash-fill'" role="button"></b-icon>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <b-form-invalid-feedback id="newpass2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </validation-provider>
                                 </b-col>
                        </b-row>
                        </validation-observer>
                            <b-row class="my-2">
                                <b-col sm="4">
                                    <label for="surname">Прізвище</label>
                                </b-col>    
                                <b-col sm="8">
                                    <validation-provider
                                        name="Прізвище"
                                        :rules="{required: true, alpha_spaces: true}"
                                        v-slot="validationContext"
                                    >
                                        <b-form-input
                                            id="surname" name="surname" ref="surname"
                                            v-model.lazy="regdata.surname" type="text"
                                            :state="getValidationState(validationContext)"
                                            aria-describedby="surname-live-feedback"
                                            autocomplete="new-password" spellcheck="false" 
                                            autocorrect="off" autocapitalize="off" 
                                        />
                                        <b-form-invalid-feedback id="surtname-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </validation-provider>
                                </b-col>
                            </b-row>

                            <b-row class="my-2">
                                <b-col sm="4">
                                    <label for="firstname">Ім'я</label>
                                </b-col>    
                                <b-col sm="8">
                                    <validation-provider
                                        name="Ім'я"
                                        :rules="{required: true, alpha_spaces: true}"
                                        v-slot="validationContext"
                                    >
                                        <b-form-input
                                            id="firstname" name="firstname" ref="firstname"
                                            v-model.lazy="regdata.firstname" type="text"
                                            :state="getValidationState(validationContext)"
                                            aria-describedby="firstname-live-feedback"
                                            autocomplete="new-password" spellcheck="false" 
                                            autocorrect="off" autocapitalize="off" 
                                        />
                                        <b-form-invalid-feedback id="firstname-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </validation-provider>
                                </b-col>
                            </b-row>

                            <b-row class="my-2">
                                <b-col sm="4">
                                    <label for="secondname">По-батькові</label>
                                </b-col>    
                                <b-col sm="8">
                                    <validation-provider
                                        name="По-батькові"
                                        :rules="{alpha_spaces: true}"
                                        v-slot="validationContext"
                                    >
                                        <b-form-input
                                            id="secondname" name="secondname" ref="secondname"
                                            v-model.lazy="regdata.secondname" type="text"
                                            :state="getValidationState(validationContext)"
                                            aria-describedby="secondname-live-feedback"
                                            autocomplete="new-password" spellcheck="false" 
                                            autocorrect="off" autocapitalize="off" 
                                        />
                                        <b-form-invalid-feedback id="secondname-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </validation-provider>    
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col class="text-left">

                            <b-button ref="button"  
                                variant="bruvis"
                                @click="step='checkemail',emailcode='',emailprecode=''"
                            >   
                                <b-icon icon="chevron-double-left" class="mr-2"/>Повернутися
                            </b-button>

                                </b-col>
                                <b-col class="text-right">

                            <b-overlay
                                :show="regbusy" rounded opacity="0.6" spinner-small
                                spinner-variant="bruvis" class="d-inline-block"
                                @hidden="onHidden"
                            >
                                <b-button
                                    ref="button" type="submit"
                                    :disabled="regbusy || regdisable" variant="bruvis"
                                >
                                    <b-icon icon="person-check-fill" class="mr-2"/>Реєстрація
                                </b-button>
                            </b-overlay>

                            </b-col>
                            </b-row>

                        </b-form>
                    </validation-observer>
                </b-card-text>
            </b-card>
        </template>
        <!--------------------------------------------------------------->
        <!----final begin----------------------------------------------------------->
        <template v-if="step==='final'">
            <b-card title="Реєстрацію завершено">
                <b-card-text>
                    <b-row>
                        <b-col class="text-left">
                            <div v-if="newuserinfo">{{newuserinfo}}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-center">
                            <b-button
                                ref="button"
                                variant="bruvis"
                                @click="step='first'"
                            >
                                <b-icon icon="person-check-fill" class="mr-2"/>Завершити
                            </b-button>

                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </template>
        <!------------final end------------------------------>
    </div>      
</template>

<script>
export default {
    name: 'LoginWindow',
    props: {
      settings: {
        type: Object,
        required: false
    },
    },

    data: () => ({
        nameLogin: "",
        passwordLogin: "",
        emptyFields: false,
        error_login_msg: '',
        step: 'first',
        email : null,
        emailbusy: false,
        emailcode: null,
        emailprecode: null,
        emailcodebusy: false,
        regdisable: true,
        /*
        phone: null,
        viber: null,
        pass: null,
        pass2: null,
        firstname: null,
        surname: null,
        secondname: null,
        */
        showpass: false,
        regbusy: false,
        regdata: {},
        phone: {},
        phonealert: false,
        newuserinfo: null,
        vueTel: {
            props: {
              preferredCountries: ["UK"],
                placeholder: "Введіть номер телефону",
                mode: "international",
                inputOptions: {
                    showDialCode: true
                },
                disabledFormatting: false,
                wrapperClasses: "country-phone-input"
            }
        },
        

    }),  

    methods: {
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        resetForm() {
            this.email = null
            this.$nextTick(() => {
                if(this.$refs.observer1) this.$refs.observer1.reset()
                //if(this.$refs.observer) this.$refs.observer.reset()
                if(this.$refs.observer3) this.$refs.observer3.reset()
            });
        },
        onSubmit() {
            this.checkEmail(this.email)
        },
        /*
        onSubmitCode() {
            this.checkEmailCode(this.email,this.emailcode)
        },
        */
        onSubmitRegform(){
            console.log('Registration')
            this.doReg()
        },
        onHidden() {
            this.$refs.button.focus()
        },
        checkEmail(email){
            
            var axiosConfig = {
                headers: {
                'Content-Type': 'application/json'
                },
                'withCredentials': true
            }
            
            this.emailbusy = true 
            
            //this.$axios.post('/', {"action":"checkemail","email":email}, axiosConfig )
            this.$axios.post('/', {"action":"sendconfirmemail","email":email}, axiosConfig )
                        .then((response) => {
                            if(response.data.success != false && response.data.success != "false"){
                                //this.$toasted.success('Пароль змінено').goAway(6000)
                                console.log(response.data)
                                this.emailbusy = false 
                                this.resetForm()
                                this.step = 'entercode'
                                this.emailprecode = response.data.code1
                                this.email = response.data.email

                                //for debug
                                console.log(response.data.code2)

                            }else{
                                this.emailbusy = false 
                                console.log('Error!')
                                console.log(response.data)
                            }
                        })
                        .catch(error => {
                            this.busy = false  
                            console.error('There was an error!', error)
                            this.$toasted.error('Помилка: '+error).goAway(4000)
                        })
            this.emailbusy = false
            console.log(email)           
        },
        checkEmailCode(){
            
            var axiosConfig = {
                headers: {
                'Content-Type': 'application/json'
                },
                'withCredentials': true
            }
            
            this.emailcodebusy = true 

            this.$axios.post('/', {"action":"checkemailcode","email":this.email,"code":this.emailcode,"precode":this.emailprecode}, axiosConfig )
                        .then((response) => {
                            if(response.data.success != false && response.data.success != "false"){
                                this.email = response.data.email
                                this.emailcodebusy = false 
                                this.step='mainform'
                                //this.resetForm()
                            }else{
                                this.$toasted.error('Код невірний').goAway(6000)
                                this.emailcode = ''
                                this.$refs.emailcode.focus()
                                this.emailcodebusy = false  
                            }
                        })
                        .catch(error => {
                            this.emailcodebusy = false  
                            console.error('There was an error!', error)
                            this.$toasted.error('Помилка: '+error).goAway(4000)
                        })
            this.emailcodebusy = false 
        },
        doReg(){

            var axiosConfig = {
                headers: {
                'Content-Type': 'application/json'
                },
                'withCredentials': true
            }

            this.regdata.email = this.email
            console.log(this.regdata)
           
            var postData = {"action":"registration","regdata":this.regdata}
            this.$axios.post('/', postData,  axiosConfig)
                .then((response) => {
                    if(response.data.success != false ){
                        this.regdata = {}
                        this.step = "final"
                        this.newuserinfo = response.data.visitor

                        //this.posts      = response.data

                        this.regbusy = false
                        //this.$toasted.success('Loaded successfull').goAway(2000)
                    }else{
                        this.regbusy = false
                        this.$toasted.error('Помилка створення облікового запису:( Спробуйте пізніше.').goAway(4000)
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error)
                });
                
        },
        validatePhone: function ({ number, valid, formatted, countryCallingCode, nationalNumber }) {
            this.regdisable = (valid !== true)
            this.phonealert = (valid === false)
            if(valid){
                /*
                this.regdata.phone.number = number
                this.regdata.phone.formatted = formatted
                this.regdata.phone.countryCallingCode = countryCallingCode
                this.regdata.phone.nationalNumber = nationalNumber
                */
                this.phone.number = number
                this.phone.formatted = formatted
                this.phone.countryCallingCode = countryCallingCode
                this.phone.nationalNumber = nationalNumber

                this.regdata.phonedata = this.phone
            }
        },
        doLogin(){
     
            if (this.nameLogin === "" || this.passwordLogin === "") {
                this.emptyFields = true;
            } else {
                this.busy = true
                var postData = {
                "action":"login",
                "username":this.nameLogin,
                "password":this.passwordLogin
                }
                var axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    },
                'withCredentials': true
                }
                this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
                        .then((response) => {
                        this.error_login_msg=response.data.message
                        if(response.data.success === true){
                            console.log('Success login')
                            this.$emit('onlogin')
                            this.busy = false
                        }else{
                            this.busy = false
                        }
                        })
                        .catch(error => {
                            console.error('There was an error!', error)
                        }); 
            }      
        },
    }    
}
</script>

<style scoped>
    
    .powered{
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .powered img{
        vertical-align: bottom;
    }

    .login-modal-body{
        background: url(/img/bg_login.jpg) no-repeat center bottom;
        background-size: cover;
        padding: 120px 0px 50px;
        min-height: 529px;
        position: relative;
        overflow: hidden;
        /*border-radius: 10px;*/
    }

    .login-form{
        width:160px;
        position: absolute;
        top: 180px;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #login {
        padding:0;
    }

    .country-phone-input .dropdown:focus {
        outline: none;
        color: grey;
    }

    .phone_not_valid div.vue-tel-input {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }


</style>