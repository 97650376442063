<template>
              <div v-if="vsdays && vsdays.length>0">
                <b-form-select
                  v-model="zakazy_payed_index"
                  class="d-block d-lg-none"
                >
                  <template v-for="(dt,index) of vsdays">
                    <b-form-select-option 
                      :key="index" 
                      :value="index"
                    >  
                      {{new Date(dt) | dateFormat('dddd D MMMM')}}
                    </b-form-select-option>
                  </template>
                </b-form-select>

                <b-card no-body>
                  <b-tabs 
                    small 
                    pills 
                    card 
                    vertical 
                    v-model="zakazy_payed_index"
                    nav-wrapper-class="d-none d-lg-block payed"
                    active-nav-item-class = "bg-bruvis text-white"
                  >
                    <!--b-tab v-for="(dt,index) of  Object.keys(zakazy_payed)" :title="new Date(dt) | dateFormat('dddd D MMMM')" :key="index" class="text-center"-->
                    <b-tab v-for="(dt,index) of vsdays" :title="new Date(dt) | dateFormat('dddd D MMMM')" :key="index" class="text-center">  
                      <template v-if="zakazy_payed && zakazy_payed[dt] != null">
                        <!-------tickets--------->
                        <div class="perforated_check mx-auto" v-for="(ch,index2) of zakazy_payed[dt]" :key="index2">
                          <svg width="200px" viewBox="0 0 300 5" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0,0 300,0" fill="none" stroke="#e2e3de" stroke-dasharray="3 3" stroke-width="20"/>
                          </svg>
                          <b-icon
                            v-if="ch.ticketRN"
                            icon="download" 
                            class="ico-download"
                            variant="dark"
                            font-scale="1.3"
                            v-b-popover.hover.topright="'Загрузити квіток'"
                            role="button"
                            @click="ticketDownload(ch.ticketRN,ch.code)"
                          ></b-icon>
                          <table class="teble mx-auto w-100">
                            <tr :key="index2">
                              <th colspan=2 class="text-center pb-3 pt-3">
                                Квиток № {{ch.ticketRN}}<br>
                                {{new Date(dt) | dateFormat('DD.MM.YYYY')}} {{ch.smena}}
                                <!--template v-if="ch.canceled"><div class="shtamp-canceled">Анульовано!</div></template-->
                              </th>
                            </tr>
                            <tr :key="index2">
                              <td class="text-left pr-3 pl-1">{{ch.xnomenu}}</td>
                              <td class="text-right text-nowrap pl-3 pr-1">{{ch.objname}}</td>
                            </tr>
                            <tr :key="index2">
                              <td colspan="2" class="text-left pr-3 pl-1">{{getSeatLabel(ch.namerow,ch.rownum)}} {{getSeatLabel(ch.namecol,ch.seat)}}</td>
                              <!--td class="text-right text-nowrap pl-3 pr-1">{{ch.serviceRN}}</td-->
                            </tr>
                            <!--tr class="bordered-top" :key="index2" v-if="ch.totalDiscountSum > 0">
                              <td class="text-left pr-3 pl-1">Знижка</td>
                              <td class="text-right pl-3 pr-1 text-nowrap">- {{ch.totalDiscountSum | toCurrency}}</td>
                            </tr-->
                            <tr :key="index2">
                              <th class="text-left pr-3 pl-1">Разом сплачено</th>
                              <th class="text-right pl-3 pr-1">{{ch.paySum | toCurrency}}</th>
                            </tr>
                            <!--tr :key="index2" v-if="ch.paytype">
                              <th class="text-left pr-3 pl-1">Paytype</th>
                              <th class="text-right pl-3 pr-1">{{ch.paytype}}</th>
                            </tr-->
                            <!--tr :key="index2" v-if="ch.canceled">
                              <th class="text-left pr-3 pl-1 comment-canceled">Повернено на баланс клубної карти</th>
                              <th class="text-right pl-3 pr-1 comment-canceled">{{ch.sumToPay | toCurrency}}</th>
                            </tr-->
                          </table>  

                        </div>
                        <!--tickets-end-->
                      </template>
                      <template v-if="vsts[dt] != null">  
                        <!-- abon-visits-->
                        <div class="perforated_check perforated_check_abon mx-auto" v-for="(v,index2) of vsts[dt]" :key="index2">
                          <svg width="200px" viewBox="0 0 300 5" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0,0 300,0" fill="none" stroke="#e2e3de" stroke-dasharray="3 3" stroke-width="20"/>
                          </svg>
                          <b-icon
                            icon="x-circle-fill" 
                            class="ico-del-zakaz "
                            variant="danger"
                            font-scale="1.3"
                            v-b-popover.hover.topright="'Анулювати візит'"
                            role="button"
                            @click="annulOrder(ch.rn)"
                          ></b-icon>
                          <table class="teble mx-auto w-100">
                            <tr :key="index2">
                              <th colspan=2 class="text-center pb-3 pt-3">
                                Абонемент "{{v.xvisitnomenu}}"<br>
                                <!--Квіток № {{v.rn}}<br-->
                                {{new Date(dt) | dateFormat('DD.MM.YYYY')}}<br>
                                {{new Date(v.ptimefr) | dateFormat('HH:mm')}}-{{new Date(v.ptimeto) | dateFormat('HH:mm')}} 
                                <!--template v-if="ch.canceled"><div class="shtamp-canceled">Анульовано!</div></template-->
                              </th>
                            </tr>
                            <tr :key="index2">
                              <td class="text-left pr-3 pl-1">{{v.xvisitnomenu}}</td>
                              <td class="text-right text-nowrap pl-3 pr-1">----</td>
                            </tr>
                            <tr :key="index2">
                              <td class="text-left pr-3 pl-1">{{v.xhall}}</td>
                              <td class="text-right text-nowrap pl-3 pr-1">----</td>
                            </tr>
                            <!--tr class="bordered-top" :key="index2" v-if="ch.totalDiscountSum > 0">
                              <td class="text-left pr-3 pl-1">Знижка</td>
                              <td class="text-right pl-3 pr-1 text-nowrap">- {{ch.totalDiscountSum | toCurrency}}</td>
                            </tr-->
                            <!--tr :key="index2">
                              <th class="text-left pr-3 pl-1">Разом сплачено</th>
                              <th class="text-right pl-3 pr-1">{{ch.sumToPay | toCurrency}}</th>
                            </tr-->
                            <!--tr :key="index2" v-if="ch.paytype">
                              <th class="text-left pr-3 pl-1">Paytype</th>
                              <th class="text-right pl-3 pr-1">{{ch.paytype}}</th>
                            </tr-->
                            <!--tr :key="index2" v-if="ch.canceled">
                              <th class="text-left pr-3 pl-1 comment-canceled">Повернено на баланс клубної карти</th>
                              <th class="text-right pl-3 pr-1 comment-canceled">{{ch.sumToPay | toCurrency}}</th>
                            </tr-->
                          </table>  
                        </div>
                        

                        <!-- abon-visits-end-->
                      </template>
                    </b-tab>
                  </b-tabs>
                </b-card>  
                <!--------------------------------------------------------->
       
              </div>
              <div v-else class="text-center">
                <h5>Бронювань немає</h5>
              </div>
            


</template>

<script>
export default {
  name: 'ZakazyPayed',
  props: {
    zakazy_payed: {
      type: Object,
      required: true
    },
    visits: {
      type: Object,
      required: true
    }
  },
  data: () => ({
      zakazy_payed_index: 0,
      annulvariantbusy: false,
      vsdays:[],
      vsts: {}
  }),
  mounted() {
    var dt
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const todayDate = new Date(today)
    console.log('abons',this.visits.abons)
    if(this.visits && this.visits.abons){
      Object.keys(this.visits.abons).forEach((a) => {
        console.log('abon',a)
        this.visits.abons[a].abonVisits.forEach((v)=>{
          if(new Date(v.ptimefr) >= todayDate){
            dt = v.ptimefr.substring(0,10)
            if(!this.vsts[dt]){
              this.vsts[dt]=[]
              this.vsdays.push(dt)
            }   
            v.abon = a
            this.vsts[dt].push(v)
          }
        })
      })
      console.log('vsdays',this.vsdays)
    }
    if(this.zakazy_payed)  
      Object.keys(this.zakazy_payed).forEach((t)=>{
        if(!this.vsdays.includes(t)) this.vsdays.push(t)
      })
    this.vsdays.sort()
  },
  methods:{

    showMsgBoxOrderAnnulConfirm(rn) {
      //**************

      this.$axios.post('/', {"action":"preannulorder","rn":rn } )
        .then((response) => {
          if(response.data.success != false && response.data.success != 'false'){
            //  аннулирование возможно
            if(response.data.paytype===2 || (response.data.paytype===3 && !response.data.creditcardenable)) {
              //***************************************************************************************** */
              this.$bvModal.msgBoxConfirm('При анулюванні оплаченого замовлення кошти зараховуються на баланс клубної карти. Ви дійсно бажаєте продовжити?', {
                          title: 'Підтвердження',
                          size: 'sm',
                          buttonSize: 'sm',
                          okVariant: 'danger',
                          okTitle: 'Анулювати',
                          cancelVariant: 'success',
                          cancelTitle: 'Відмінити',
                          footerClass: 'p-2',
                          hideHeaderClose: false,
                          centered: true
                        })
                        .then(value => {
                              if(value === true) {
                                return this.annulOrder(rn,'true')
                              }else if(value === false) {
                                return 
                              }
                        })
                        .catch(err => {
                          console.log('Annul order '+err)
                        })
              //***************************************************************************************** */
            }else if(response.data.paytype===3) {
               this.$bvModal.msgBoxConfirm('Це замовлення було сплачено через LiqPay. Бажаєте нарахувати кошти на клубну карту, чи замовити повернення через Liqpay?', {
                          title: 'Повернення коштів',
                          size: 'sm',
                          buttonSize: 'sm',
                          okVariant: 'bruvis',
                          okTitle: 'Клубна карта',
                          cancelVariant: 'success',
                          cancelTitle: 'LiqPAY',
                          footerClass: 'p-2',
                          hideHeaderClose: false,
                          centered: true
                        })
                        .then(value => {
                              if(value === true) {
                                return this.annulOrder(rn,'true')
                              }else if(value === false) {
                                this.$toasted.success('Типа повернення через LiqPAY').goAway(3000)
                                return 
                              }
                        })
                        .catch(err => {
                          console.log('Annul order '+err)
                        })

            }  
          }else{
            if(response.data.error=="init"){
              this.$emit('needlogin')
            }else{
              this.$bvModal.msgBoxOk(response.data.message, {
                title: 'Попередження',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'bruvis',
                okTitle: 'Ok',
                footerClass: 'p-2',
                hideHeaderClose: true,
                centered: true,
                headerClass: 'bg-danger text-white border-danger',
              })
              .then(value => {
                console.log(value)
                return
              })
            }
          }  
        })
        .catch(error => {
          this.ordersbusy = false
          console.error('There was an error!', error)
        });
    
    },

    annulOrder(rn,confirm) {
      if(!confirm){
        this.showMsgBoxOrderAnnulConfirm(rn)
        return
      }
      if(confirm === 'false'){
        return
      }
      //this.$emit('globalbusy')
      //this.ordersbusy = true
      this.$axios.post('/', {"action":"annulorder","rn":rn } )
        .then((response) => {
          if(response.data.success != false && response.data.success != 'false'){
            this.$toasted.success(response.data.message).goAway(3000)
            this.$emit('ondelete')
            //this.ordersbusy = false
          }else{
            //this.ordersbusy = false
            if(response.data.error=="init"){
              this.$emit('needlogin')
            }else{
              this.$toasted.error(response.data.message).goAway(3000) 
            }
          }  
        })
        .catch(error => {
          this.ordersbusy = false
          console.error('There was an error!', error)
        });
      
    },
     ticketDownload(fl,code){
      var axiosConfig = {
        /*
        headers: {
          'Content-Type': 'application/json',
        },
        */
        'withCredentials': true,
        'responseType': 'blob'
      }
      this.$axios.post('/', {"action":"download","rn":fl,"code":code}, axiosConfig )
        .then((res) => {
          console.log(res.data.success,res.data.error)
          if(res.data.success != false && res.data.success != 'false'){
            var FILE = window.URL.createObjectURL(new Blob([res.data]));
            var docUrl = document.createElement('a');
            docUrl.href = FILE;
            docUrl.setAttribute('download', 'ticket_'+fl+'.pdf');
            document.body.appendChild(docUrl);
            docUrl.click(); 
            ////document.body.removeChild(docUrl);
            ////window.URL.revokeObjectURL(FILE);
            console.log(res)
          }else{
            if(res.data.error=="init"){
              this.$emit('needlogin')
            }
          } 
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
    },

    getSeatLabel(nam,num){
      if(!nam)return ''
      if(nam.indexOf('#')>-1)
        return nam.replace('#',num)
      else
        return nam
    },

  }
}
</script>

<style scoped>

div.payed ul.nav-pills li.nav.item a.nav-link {
  border-width: 0!important;
}

.check{
  padding: 5px;
  background:
    linear-gradient(-45deg, #0000 6px, #fff 7px),
    linear-gradient( 45deg, #0000 6px, #fff 7px),
    linear-gradient(-45deg, #fff calc(100% - 7px), #0000 calc(100% - 8px)),
    linear-gradient( 45deg, #fff calc(100% - 7px), #0000 calc(100% - 8px));
  background-size: 10px 50%;
  background-position: 0 100%, 0 100%, 0 0, 0 0;
  background-repeat: repeat-x;
  font-family: courier;
}

.perforated_check{
  position: relative;
  margin: 3px;
  width: 400px;
  min-height: 200px;
  background: #e2e3de;
  padding: 4px 20px;
  box-shadow: 10px 10px 20px rgb(0 0 0 / 20%);
  font-family: courier;
}

.perforated_check_abon{
  background-color: #fdf3ce;
}

.perforated_check svg{
  position: absolute;
  top: -3px;
  left: 2px;
  width: 100%;
}

.perforated_check table td{
  border-bottom: 1px dashed #c5c5c5;
  vertical-align: top;
}

.perforated_check table {
  margin-bottom:20px;
}

svg.ico-download{
  position: absolute;
  top: 23px;
  left: 21px;
  width: 30px;
}  


svg.ico-del-zakaz{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute;
  top: 23px;
  left: 21px;
  width: 30px;
}  

.perforated_check:hover svg.ico-del-zakaz{
  visibility: visible;
  opacity: 1;
}

.shtamp-canceled{
  color: #b9333f;
  background-color: transparent;
  border: 3px solid #b9333f;
  border-radius: 0.25rem;
  position: absolute;
  padding: 0.75rem 1.25rem;
  transform: rotate(45deg);
  opacity: 0.7;
}

.comment-canceled{
  color: #b9333f;
  padding-top: 0.5em;
}  


@media (max-width: 575px) {
  .perforated_check{
    width: 100%;
/*    min-height: 200px;*/
  }
  .card-body {
    padding:0;
  }

}

@media (hover: none) {
  svg.ico-del-zakaz{
    visibility: visible;
    opacity: 1;
  }    

}    


</style>
